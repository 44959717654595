import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BlockUi from 'react-block-ui';
import * as Yup from 'yup';
import { Loader } from 'react-loaders';
import BackIcon from '@static/images/back-icon.svg';
import { Link } from 'react-router-dom';
import helpicon from '@static/images/caretdoubleright.svg';
import BreadcrumbIcon from '@static/images/breadcrumb-icon.svg';
import TextError from '@modules/shared/components/reusable-components/text-error';
import Help from '@modules/shared/components/reusable-components/help';
import Multiselect from 'multiselect-react-dropdown';
import { APP_LABELS, META_DATA } from '@config/app-config';
import CustomModal from '@utilities/CustomModal';

import {
  getCategoryItems,
  addCategoryMappings,
  getCategoryMappings
} from '@redux/metadata-manager/categories/actions';
import {
  getDashboardForms,
  getDomains,
  getYears
} from '@redux/metadata-manager/dashboard/actions';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { handleResponse, handleCatch } from '../shared/index';

const MetaDataManagerCategoryMapping = props => {
  const {
    handleGetCategoriesItems,
    handleGetDashboardForms,
    handleAddCategoryMappings,
    handleGetCategoryMappings,
    handleGetDomains,
    handleGetYears
  } = props;
  const [help, setHelp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [optionsList, setOptionsList] = useState(false);
  const [allLists, setAllLists] = useState({
    domine: [],
    years: [],
    category1: [],
    category2: [],
    category3: []
  });
  const [subformList, setSubformList] = useState([]);
  const [selectedDataDomain, setSelectedDataDomain] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [formList, setFormList] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFailure, setShowFailure] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [initialValues, setInitialValues] = useState({
    formId: '',
    subFormId: '',
    category1Id: '',
    category2Id: null,
    category3Id: null
  });
  const validationSchema = Yup.object({
    formId: Yup.string().nullable().required('Form is Required'),
    subFormId: Yup.string().nullable().required('Subform is Required'),
    category1Id: Yup.string().nullable().required('Category 1 is Required'),
    category2Id: Yup.string().nullable(),
    category3Id: Yup.string().nullable()
  });

  const categoryMappingId = sessionStorage.getItem('categoryMappingId');
  const selectedFileds = sessionStorage.getItem('selectedFileds');
  const handleShowHelp = () => {
    setHelp(true);
  };
  const hideShowHelp = () => {
    setHelp(false);
  };

  const sortCategories = (data, key) => {
    // const sortedData = data?.sort((a, b) =>
    //   a[key]
    //     .trim()
    //     .toUpperCase()
    //     .localeCompare(
    //       b[key].toUpperCase(),
    //       'de',
    //       {
    //         sensitivity: 'base'
    //       }
    //     )
    // )
    // return sortedData
    const sortedData = data.sort((a, b) => {
      if (a[key]?.toLowerCase()?.trim() < b[key]?.toLowerCase()?.trim())
        return -1;
      if (a[key]?.toLowerCase()?.trim() > b[key]?.toLowerCase()?.trim())
        return 1;
      return 0;
    });

    return sortedData;
  };

  useEffect(async () => {
    setIsLoading(true);

    const [domainsRes, yearsRes, category1, category2, category3] =
      await Promise.all([
        handleGetDomains(),
        handleGetYears(),
        handleGetCategoriesItems(1),
        handleGetCategoriesItems(2),
        handleGetCategoriesItems(3)
      ]);

    setAllLists({
      domine: handleResponse(domainsRes).data || [],
      years: handleResponse(yearsRes).data || [],
      category1: sortCategories(handleResponse(category1).data, 'name') || [],
      category2: sortCategories(handleResponse(category2).data, 'name') || [],
      category3: sortCategories(handleResponse(category3).data, 'name') || []
    });
    setIsLoading(false);

    if (JSON.parse(selectedFileds)) {
      let fields = JSON.parse(selectedFileds);
      setSelectedDataDomain(fields?.domine);
      setSelectedYear(fields?.year);
    }

    if (categoryMappingId) {
      const categoryResponse = await handleGetCategoryMappings(
        categoryMappingId
      );
      const { formId, subFormId, category1Id, category2Id, category3Id } =
        handleResponse(categoryResponse).data;
      setInitialValues({
        formId,
        subFormId,
        category1Id,
        category2Id,
        category3Id
      });
    }
  }, []);

  useEffect(async () => {
    if (selectedDataDomain && selectedYear) {
      setIsLoading(true);
      try {
        const response = await handleGetDashboardForms(
          selectedDataDomain,
          selectedYear
        );
        const list =
          handleResponse(response)?.data?.map(res => {
            return {
              ...res?.form,
              subForms: [...res?.subForms]
            };
          }) || [];

        setFormList(sortCategories(list, 'formName'));
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    }
  }, [selectedDataDomain, selectedYear]);

  useEffect(() => {
    if (
      initialValues?.formId &&
      subformList?.length === 0 &&
      formList?.length > 0
    ) {
      let list = formList?.find(res => res?.formId === initialValues?.formId);

      setSubformList(sortCategories(list?.subForms, 'subFormName'));
    }
  }, [initialValues, formList]);

  const onSubmit = async values => {
    setIsLoading(true);
    const payload = {
      formId: values?.formId && Number(values?.formId),
      subFormId: values?.subFormId && Number(values?.subFormId),
      category1Id: values?.category1Id && Number(values?.category1Id),
      category2Id: (values?.category2Id && Number(values?.category2Id)) || null,
      category3Id: (values?.category3Id && Number(values?.category3Id)) || null,
      domainId: sessionStorage.getItem('categorydomainId'),
      year: sessionStorage.getItem('categoryyear')
    };
    try {
      const response = await handleAddCategoryMappings(
        payload,
        categoryMappingId
      );
      const result = handleResponse(response);

      if (result.isSuccess) {
        setShowSuccess(true);
      } else {
        setShowFailure(true);
        setErrorMessage(result.message);
      }
      setIsLoading(false);
    } catch (error) {
      const err = await handleCatch(error);
      setShowFailure(true);
      setErrorMessage(result.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleForm = (e, setFieldValue) => {
    setFieldValue('formId', e.target.value);
    setFieldValue('subFormId', '');
    const list = formList.filter(
      item => item?.formId?.toString() === e.target.value.toString()
    );
    setSubformList(sortCategories(list[0]?.subForms || [], 'subFormName'));
  };
  return (
    <BlockUi
      blocking={isLoading}
      message="Loading, please wait"
      loader={<Loader active type="semi-circle-spin" />}
    >
      <div className="ymca-wrapper manage-metadata-page">
        <main className="page-container">
          <button
            type="button"
            className="ymca-help-link text-decoration-none ymca-up-data-help-link  "
            onClick={handleShowHelp}
          >
            HELP{/* */}
            <img src={helpicon} className="img-fluid" alt="caret-icon" />
          </button>
          <div className="container">
            <div className="d-flex justify-content-between">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb ymca-breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/metadata-manager/dashboard">
                      <span>Dashboard</span>
                    </Link>
                    <img
                      src={BreadcrumbIcon}
                      className="breadcrumb-icon"
                      alt=""
                    />
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/metadata-manager/categories">
                      <span>Categories</span>
                    </Link>
                    <img
                      src={BreadcrumbIcon}
                      className="breadcrumb-icon"
                      alt=""
                    />
                  </li>
                  <li className="breadcrumb-item active">
                    {categoryMappingId && 'Edit'} Category Mapping
                  </li>
                </ol>
              </nav>
              <Link
                className="ymca-title-link"
                to="/metadata-manager/categories"
                id="categories_id"
              >
                <img src={BackIcon} className="me-1 mb-2" alt="back-icon" />{' '}
                Back
              </Link>
            </div>
            <h5 className="ymca-sub-heading mb-0">
              {categoryMappingId && 'Edit'} Category Mapping
            </h5>
          </div>
          <hr />
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({ setFieldValue, values }) => (
              <Form autoComplete="off" className="ymca-registeration-form">
                <div className="container">
                  <div className="row ymca-form">
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label htmlFor="formId" className="form-label">
                          Form<span className="text-mandatory ms-1">*</span>
                        </label>
                        <Field
                          as="select"
                          name="formId"
                          className="form-select"
                          aria-label="Default select example"
                          onChange={e => handleForm(e, setFieldValue)}
                        >
                          <option value="" label="Select" />
                          {formList?.map(item => (
                            <option key={item.formId} value={item.formId}>
                              {item.formName}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage component={TextError} name="formId" />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label htmlFor="subFormId" className="form-label">
                          Sub-Form<span className="text-mandatory ms-1">*</span>
                        </label>
                        <Field
                          as="select"
                          name="subFormId"
                          className="form-select"
                          aria-label="Default select example"
                        >
                          <option value="">Select</option>
                          {subformList?.map(item => {
                            return (
                              <option value={item?.subFormId}>
                                {item?.subFormName}
                              </option>
                            );
                          })}
                        </Field>
                        <ErrorMessage component={TextError} name="subFormId" />
                      </div>
                    </div>
                  </div>

                  <div className="row ymca-form">
                    <div className="col-12 col-md-6 col-lg-4">
                      <div className="form-group">
                        <label htmlFor="category1Id" className="form-label">
                          Category1
                          <span className="text-mandatory ms-1">*</span>
                        </label>

                        <Field
                          name="category1Id"
                          id="category1Id"
                          component={() => {
                            return (
                              <Multiselect
                                style={{ border: '0px ' }}
                                singleSelect
                                options={allLists?.category1}
                                displayValue="name"
                                selectedValues={allLists?.category1.filter(
                                  option => option.id === values.category1Id
                                )}
                                onSelect={(selectedList, selectedItem) => {
                                  setFieldValue('category1Id', selectedItem.id); // Set form value on select
                                }}
                                onRemove={() => {
                                  setFieldValue('category1Id', null); // Clear form value on remove
                                }}
                              />
                            );
                          }}
                          options={allLists?.category1}
                        />
                        <ErrorMessage
                          component={TextError}
                          name="category1Id"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                      <div className="form-group">
                        <label htmlFor="category2Id" className="form-label">
                          Category2
                        </label>
                        <Field
                          name="category2Id"
                          id="category2Id"
                          component={() => {
                            return (
                              <Multiselect
                                singleSelect
                                hidePlaceholder
                                options={allLists?.category2}
                                displayValue="name"
                                selectedValues={allLists?.category2.filter(
                                  option => option.id === values.category2Id
                                )}
                                onSelect={(selectedList, selectedItem) => {
                                  setFieldValue('category2Id', selectedItem.id); // Set form value on select
                                }}
                                onRemove={() => {
                                  setFieldValue('category2Id', null); // Clear form value on remove
                                }}
                              />
                            );
                          }}
                          options={allLists?.category2}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                      <div className="form-group">
                        <label htmlFor="category3Id" className="form-label">
                          Category3
                        </label>
                        <Field
                          name="category3Id"
                          id="category3Id"
                          component={() => {
                            return (
                              <Multiselect
                                singleSelect
                                options={allLists?.category3}
                                displayValue="name"
                                selectedValues={allLists?.category3.filter(
                                  option => option.id === values.category3Id
                                )}
                                onSelect={(selectedList, selectedItem) => {
                                  setFieldValue('category3Id', selectedItem.id); // Set form value on select
                                }}
                                onRemove={() => {
                                  setFieldValue('category3Id', null); // Clear form value on remove
                                }}
                              />
                            );
                          }}
                          options={allLists?.category3}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-end mt-2">
                    <Link
                      className="btn ymca-primary-outline-btn"
                      type="button"
                      to="/metadata-manager/categories"
                    >
                      Cancel
                    </Link>
                    <button className="btn ymca-primary-btn ms-3" type="submit">
                      Submit
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </main>
        {help && <Help hidePopup={hideShowHelp} keyValue="category-mapping" />}
      </div>
      <CustomModal
        show={showSuccess}
        type={APP_LABELS.SUCCESS}
        message={META_DATA.ADD_META_DATA_SUCCESS}
        submitButtonName="Ok"
        onSubmit={() => document.getElementById('categories_id').click()}
      />

      <CustomModal
        show={showFailure}
        type={APP_LABELS.ERROR}
        message={errorMessage || APP_LABELS.ERROR_MESSAGE}
        submitButtonName="Ok"
        onSubmit={() => setShowFailure(false)}
      />
    </BlockUi>
  );
};

MetaDataManagerCategoryMapping.propTypes = {
  handleGetDomains: PropTypes.func.isRequired,
  handleGetYears: PropTypes.func.isRequired,
  handleGetCategoriesItems: PropTypes.func.isRequired,
  handleGetDashboardForms: PropTypes.func.isRequired,
  handleAddCategoryMappings: PropTypes.func.isRequired,
  handleGetCategoryMappings: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  handleGetDomains: getDomains,
  handleGetYears: getYears,
  handleGetCategoriesItems: getCategoryItems,
  handleGetDashboardForms: getDashboardForms,
  handleAddCategoryMappings: addCategoryMappings,
  handleGetCategoryMappings: getCategoryMappings
};
export default connect(
  null,
  mapDispatchToProps
)(MetaDataManagerCategoryMapping);
