import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders';
import BackIcon from '@static/images/back-icon.svg';
import { Link } from 'react-router-dom';
import BreadcrumbIcon from '@static/images/breadcrumb-icon.svg';
import SearchIcon from '@static/images/search-icon.svg';
import plusIcon from '@static/images/plus-icon-white.svg';
import crossiconblue from '@static/images/cross-icon-blue.svg';
import CustomModal from '@utilities/CustomModal';
import { APP_LABELS } from '@config/app-config';
import Table from './table';
import get from 'lodash.get';
import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';
import { METADATA_CATEGORIES_ITEMS_COLUMNS } from './table-columns';
import {
  getCategoryItems,
  addConfigureCategorieItem,
  updateConfigureCategorieItem,
  deleteConfigureCategorieItem
} from '@redux/metadata-manager/categories/actions';
import { handleResponse } from '../shared/index';

const MetaDataManagerManageCategoryItem = ({
  handleGetCategoriesItems,
  handleAddCategoriesItems,
  handleUpdateCategoriesItems,
  handleDeleteCategoriesItems,
  loginUserData,
  match
}) => {
  const [showAttentionPopup, setShowAttentionPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [selectedId, setSelectedId] = useState('');
  const [isItemAdd, setIsItemAdd] = useState('');
  const role = get(loginUserData, 'user.role[0]');
  const [item, setItem] = useState('');
  const [globalFilter, setGlobalFilter] = useState('');
  const [showFailure, setShowFailure] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const categoryItem = sessionStorage.getItem('metaData_categoryItem');

  const initializeCategories = async () => {
    setIsLoading(true);
    try {
      const response = await handleGetCategoriesItems(categoryItem);
      const result = handleResponse(response);
      if (result.isSuccess) {
        setTableData(
          response.data.data?.map(res => {
            return { ...res, name: res.name.trim() };
          })
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    initializeCategories();
  }, []);

  const deleteCategory = async () => {
    setShowAttentionPopup(false);
    setIsLoading(true);

    try {
      const response = await handleDeleteCategoriesItems(
        selectedId,
        categoryItem
      );
      const result = handleResponse(response);

      if (result.isSuccess) {
        initializeCategories();
      } else {
        setErrorMessage(result.message);
        setShowFailure(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = id => {
    setShowAttentionPopup(true);
    setSelectedId(id);
  };

  const handleAddorEditCategories = async () => {
    setIsLoading(true);
    const payload = {
      name: item.trim(),
      description: item.trim(),
      categoryItem
    };
    if (isItemAdd === 'Add') {
      try {
        const response = await handleAddCategoriesItems(payload);
        const result = handleResponse(response);
        if (result.isSuccess) {
          initializeCategories();
        } else {
          setErrorMessage(result.message);
          setShowFailure(true);
        }
      } finally {
        setIsLoading(false);
      }
    } else {
      try {
        const response = await handleUpdateCategoriesItems(selectedId, {
          ...payload,
          id: selectedId
        });
        const result = handleResponse(response);
        if (result?.isSuccess) {
          initializeCategories();
        } else {
          setErrorMessage(result.message);
          setShowFailure(true);
        }
      } finally {
        setIsLoading(false);
      }
    }
    setItem('');
  };

  const closeAddModal = () => {
    setIsItemAdd('');
    setItem('');
  };

  const handleUpdate = (id, name) => {
    setIsItemAdd('Edit');
    setItem(name);
    setSelectedId(id);
  };

  const renderTitle = () => {
    if (categoryItem == 1) return 'Category 1';
    if (categoryItem == 2) return 'Category 2';
    if (categoryItem == 3) return 'Category 3';
  };

  return (
    <BlockUi
      blocking={isLoading}
      message="Loading, please wait"
      loader={<Loader active type="semi-circle-spin" />}
    >
      <div className="ymca-wrapper">
        <main className="page-container">
          <div className="container">
            <div className="d-md-flex justify-content-between">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb ymca-breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/metadata-manager/dashboard">
                      <span>Dashboard</span>
                    </Link>
                    <img
                      src={BreadcrumbIcon}
                      className="breadcrumb-icon"
                      alt=""
                    />
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/metadata-manager/categories">
                      <span>Categories</span>
                    </Link>
                    <img
                      src={BreadcrumbIcon}
                      className="breadcrumb-icon"
                      alt=""
                    />
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/metadata-manager/categories/configure-items">
                      <span>Configure</span>
                    </Link>
                    <img
                      src={BreadcrumbIcon}
                      className="breadcrumb-icon"
                      alt=""
                    />
                  </li>
                  <li className="breadcrumb-item active">
                    Add/Edit Category Items
                  </li>
                </ol>
              </nav>
              <Link
                className="ymca-title-link"
                to="/metadata-manager/categories/configure-items"
              >
                <img src={BackIcon} className="me-1 mb-2" alt="back-icon" />{' '}
                Back
              </Link>
            </div>
            <h5 className="ymca-sub-heading mb-0">Add/Edit Category Items</h5>
          </div>
          <hr />

          <div className="container mb-4">
            <div className="row align-items-center">
              <div className="col-12 col-lg-2">
                <div className="ymca-section-heading"> {renderTitle()}</div>
              </div>
              <div className="col-12 col-lg-10">
                <div className="row align-items-center justify-content-lg-end mt-3 mt-lg-0">
                  <div className="col-12 col-md-8 col-lg-6 ">
                    <div className="search-form-wrapper mb-0">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text" id="basic-addon1">
                            <img src={SearchIcon} alt="SearchIcon" />
                          </span>
                        </div>
                        <input
                          type="text"
                          className="form-control search-form-control"
                          placeholder="Search based form section and category"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          value={globalFilter}
                          onChange={e => setGlobalFilter(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  {role !== APP_LABELS.YUSA_DATA_VIEWER && (
                    <div className="col-auto col-md-auto col-lg-auto mt-3 mt-md-0">
                      <button
                        className="btn ymca-primary-btn d-flex align-items-center"
                        type="button"
                        href=""
                        data-bs-toggle="modal"
                        data-bs-target="#addModal"
                        onClick={() => setIsItemAdd('Add')}
                      >
                        {' '}
                        <img
                          src={plusIcon}
                          className="me-2"
                          alt="plusIcon"
                        />{' '}
                        Add Item
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {tableData && (
            <Table
              rows={tableData}
              headers={METADATA_CATEGORIES_ITEMS_COLUMNS({
                handleDelete,
                handleUpdate,
                role
              })}
              showpagination
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
          )}
        </main>
      </div>

      {/* Add or Edit Item Modal */}

      <div
        className="modal fade"
        id="addModal"
        tabIndex="-1"
        aria-labelledby="#addModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered ymca-modal ymca-small-modal">
          <div className="modal-content p-3">
            <div className="modal-header py-1 border-0">
              <div className="ymca-black-sub-heading">{isItemAdd} Item</div>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={closeAddModal}
              >
                <img
                  src={crossiconblue}
                  alt="cross-icon-blue"
                  className="close-icon img-fluid"
                />
              </button>
            </div>
            <div className="modal-body">
              <div className="ymca-form">
                <div className="form-group">
                  <label htmlFor='="txtCategoryItem'> Item Name</label>
                  <textarea
                    id="txtCategoryItem"
                    maxlength="50"
                    className="form-control ymca-areaTextWidth"
                    placeholder="Enter Item Name"
                    value={item}
                    onChange={e => setItem(e.target.value)}
                  />
                </div>
              </div>
              <div className="d-flex mt-2">
                <button
                  className="btn ymca-primary-outline-btn w-100"
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={closeAddModal}
                >
                  Cancel
                </button>
                <button
                  className="btn ymca-primary-btn w-100 ms-3"
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleAddorEditCategories}
                  disabled={!item}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CustomModal
        show={showAttentionPopup}
        type={APP_LABELS.UPLOAD_FILE_ATTENTION}
        message="Are you sure want to delete?"
        submitButtonName="Yes"
        onSubmit={deleteCategory}
        cancelButtonName={APP_LABELS.UPLOAD_DATA_CANCEL}
        onCancel={() => setShowAttentionPopup(false)}
      />

      <CustomModal
        show={showFailure}
        type={APP_LABELS.ERROR}
        message={errorMessage || APP_LABELS.ERROR_MESSAGE}
        submitButtonName="Ok"
        onSubmit={() => setShowFailure(false)}
      />
    </BlockUi>
  );
};

MetaDataManagerManageCategoryItem.propTypes = {
  handleGetCategoriesItems: PropTypes.func.isRequired,
  handleAddCategoriesItems: PropTypes.func.isRequired,
  handleUpdateCategoriesItems: PropTypes.func.isRequired,
  handleDeleteCategoriesItems: PropTypes.func.isRequired,
  loginUserData: PropTypes.node.isRequired
};

const mapDispatchToProps = {
  handleGetCategoriesItems: getCategoryItems,
  handleAddCategoriesItems: addConfigureCategorieItem,
  handleUpdateCategoriesItems: updateConfigureCategorieItem,
  handleDeleteCategoriesItems: deleteConfigureCategorieItem
};
const mapStateToProps = state => ({
  loginUserData: AssociationAdminLoginDetailsSelector(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MetaDataManagerManageCategoryItem);
