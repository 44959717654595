const DATA_POINTS_COLUMN = () => [
  {
    Header: 'Association No.',
    accessor: 'Association',
    className: 'notranslate'
  },
  {
    Header: 'Eval Group Name',
    accessor: 'EvaluationGroupName',
    className: 'notranslate'
  },
  {
    Header: 'Eval Group ID',
    accessor: 'EvaluationGroupId',
    className: 'notranslate'
  },
  {
    Header: 'Data Point ID',
    accessor: 'DataPointID',
    className: 'notranslate'
  },
  {
    Header: 'Datapoint Name',
    accessor: 'Datapoint',
    className: 'notranslate'
  },
  {
    Header: 'Points Possible',
    accessor: 'PointsPossible',
    className: 'notranslate'
  },
  {
    Header: 'Points Awarded',
    accessor: 'PointsAwarded',
    className: 'notranslate'
  }
];

export default DATA_POINTS_COLUMN;
