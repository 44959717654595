import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders';
import BackIcon from '@static/images/back-icon.svg';
import { Link } from 'react-router-dom';
import BreadcrumbIcon from '@static/images/breadcrumb-icon.svg';
import SearchIcon from '@static/images/search-icon.svg';
import Table from './table';
import { getConfigureCategories } from '@redux/metadata-manager/categories/actions';
import { METADATA_CONFIGURE_CATEGORIES_COLUMNS } from './table-columns';

const MetaDataManagerConfigureCategory = ({ handleGetConfigureCategories }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);

  const [globalFilter, setGlobalFilter] = useState('');

  const initializeCategories = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await handleGetConfigureCategories();
      if (response?.data?.data) {
        setTableData(response.data.data);
      }
    } finally {
      setIsLoading(false);
    }
  }, [handleGetConfigureCategories]);

  useEffect(() => {
    initializeCategories();
  }, [initializeCategories]);

  return (
    <BlockUi
      blocking={isLoading}
      message="Loading, please wait"
      loader={<Loader active type="semi-circle-spin" />}
    >
      <div className="ymca-wrapper">
        <main className="page-container">
          <div className="container">
            <div className="d-flex justify-content-between">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb ymca-breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/metadata-manager/dashboard">
                      <span>Dashboard</span>
                    </Link>
                    <img
                      src={BreadcrumbIcon}
                      className="breadcrumb-icon"
                      alt=""
                    />
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/metadata-manager/categories">
                      <span>Categories</span>
                    </Link>
                    <img
                      src={BreadcrumbIcon}
                      className="breadcrumb-icon"
                      alt=""
                    />
                  </li>
                  <li className="breadcrumb-item active">Configure Category</li>
                </ol>
              </nav>
              <Link
                className="ymca-title-link"
                to="/metadata-manager/categories"
              >
                <img src={BackIcon} className="me-1 mb-2" alt="back-icon" />{' '}
                Back
              </Link>
            </div>
            <h5 className="ymca-sub-heading mb-0">Configure Category</h5>
          </div>
          <hr />
          <div className="container mb-4">
            <div className="row align-items-center">
              <div className="col-12 col-md-3 col-lg-7">
                <div className="ymca-section-heading"> Categories List</div>
              </div>
              <div className="col-12 col-md-9 col-lg-5 mt-3 mt-md-0">
                <div className="search-form-wrapper mb-0">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1">
                        <img src={SearchIcon} alt="SearchIcon" />
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control search-form-control"
                      placeholder="Search based form section and category"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      value={globalFilter}
                      onChange={e => setGlobalFilter(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {tableData && (
            <Table
              rows={tableData}
              headers={METADATA_CONFIGURE_CATEGORIES_COLUMNS()}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
          )}
        </main>
      </div>
    </BlockUi>
  );
};

MetaDataManagerConfigureCategory.propTypes = {
  handleGetConfigureCategories: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  handleGetConfigureCategories: getConfigureCategories
};
export default connect(
  null,
  mapDispatchToProps
)(MetaDataManagerConfigureCategory);
