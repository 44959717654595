import React, { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import moment from 'moment';
import PropTypes from 'prop-types';
import BlockUi from 'react-block-ui';
import { connect } from 'react-redux';
import { Loader } from 'react-loaders';
import { Link, Redirect } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import {
  getDashboardForms,
  getDomains,
  getYears
} from '@redux/metadata-manager/dashboard/actions';
import {
  addMetadataScoring,
  getEvalDataBySubform,
  postEvalDataByIds
} from '@redux/metadata-manager/data-scoring/actions';
import {
  addMetadata,
  getSegmentTypes,
  updateMetadata
} from '@redux/metadata-manager/manage-metadata/actions';
import { getCategoryMappingBySubForms } from '@redux/metadata-manager/categories/actions';

import CustomModal from '@utilities/CustomModal';
import { APP_LABELS, META_DATA } from '@config/app-config';
import TextError from '@modules/shared/components/reusable-components/text-error';

import BackIcon from '@static/images/back-icon.svg';
import BreadcrumbIcon from '@static/images/breadcrumb-icon.svg';

import Table from './table';
import ScoringViewDetails from './scoring-view-details';
import { METADATA_SCORING_COLUMNS } from './columns';
import { handleCatch, handleResponse } from '../shared/index';

const initialValues = {
  year: '',
  dataDomainId: '',
  dataDomainName: '',
  formName: '',
  formId: '',
  subformName: '',
  subformId: '',
  category1Name: '',
  category1Id: '',
  category2Name: '',
  category2Id: '',
  category3Name: '',
  category3Id: '',
  dataSegmentType: '',
  minPointsReq: '',
  earnedScore: '',
  evalGroup: 'All'
};

const ScoringMetaData = ({
  handleGetDomains,
  handleGetYears,
  handleGetSegmentTypes,
  handleGetDashboardForms,
  handleGetCategoryMappings,
  handleGetMetadata,
  handlePostEvalDataByIds,
  handleAddMetadataScoring,
  handleGetEvalDataBySubform
}) => {
  // const [initialValues, setInitialValues] = useState({ ...initialData });
  const [selectedRow, setSelectedRow] = useState({});

  const validationSchema = Yup.object({
    dataDomainId: Yup.string().nullable().required('Data Domain is Required'),
    year: Yup.string().nullable().required('Year is Required'),
    formId: Yup.string().nullable().required('Form is Required'),
    subformId: Yup.string().nullable().required('Subform is Required'),
    category1Id: Yup.string().nullable().required('Category 1 is Required'),
    evalGroup: Yup.string().nullable().required('Eval Group is Required'),
    minPointsReq: Yup.number()
      .typeError('Min Points Required must be a valid number')
      .nullable(),
    dataSegmentType: Yup.string()
      .nullable()
      .required('Eval Group Type is Required'),
    earnedScore: Yup.number()
      .typeError('Earned Score must be a valid number')
      .nullable()
      .required('Earned Score is Required')
  });

  const [redirect, setRedirect] = useState(false);
  const [redirectValue, setRedirectValue] = useState();
  const [showDataScroingForm, setShowDataScroingForm] = useState(false);

  const [isloading, setIsLoading] = useState('');
  const [scoringData, setScoringData] = useState([]);
  const [selectedScoreData, setSelectedScoreData] = useState({});
  const [evalGroupErrMsg, setEvalGroupErrMsg] = useState('');
  const [categoryMappingValidation, setCategoryMappingValidation] = useState(
    []
  );

  const evalGroupList = [
    { value: 'All', name: 'All' },
    { value: '1', name: 'Category 1' },
    { value: '2', name: 'Category 2' },
    { value: '3', name: 'Category 3' }
  ];

  const [state, setState] = useState({
    listOfYears: [],
    listOfDataDomains: [],
    listOfForms: [],
    listOfSubForms: [],
    listOfAllSubForms: [],
    listOfCategoryMappings: [],
    listOfSegmentTypes: [],
    listOfCategory1: [],
    listOfCategory2: [],
    listOfCategory3: [],
    listOfFormSubForm: [],
    listOfCategories: [],
    dataDomainId: '',
    year: '',
    formId: '',
    subFormId: '',
    category1: '',
    category2: '',
    category3: '',
    segmentType: '',
    evalGroup: '',
    dataEvalMinScore: '',
    earnedScore: 0,
    createdDttm: '',
    createdBy: '',
    updatedDttm: '',
    updatedBy: '',
    isLoading: false,
    showSuccess: false,
    showFailure: false,
    errorMessage: '',
    metadataId: undefined,

    tableData: [],
    errors: {} // State for form errors
  });

  const {
    listOfYears,
    listOfDataDomains,
    listOfForms,
    listOfSubForms,
    listOfSegmentTypes,
    listOfCategory1,
    listOfCategory2,
    listOfCategory3,
    listOfFormSubForm,
    listOfCategories,
    showSuccess,
    showFailure,
    errorMessage,
    isLoading,
    metadataId
  } = state;

  // Helper to update state
  const updateState = updates => setState(prev => ({ ...prev, ...updates }));

  const sortCategories = (data, key) => {
    const sortedData = data.sort((a, b) => {
      if (a[key]?.toLowerCase()?.trim() < b[key]?.toLowerCase()?.trim())
        return -1;
      if (a[key]?.toLowerCase()?.trim() > b[key]?.toLowerCase()?.trim())
        return 1;
      return 0;
    });
    return sortedData;
  };

  const loadForms = useCallback(
    async (_domainId, _year, setFieldValue) => {
      updateState({ isLoading: true });
      try {
        const response = await handleGetDashboardForms(_domainId, _year);
        const result = handleResponse(response);
        if (result.isSuccess) {
          if (result.data?.length > 0) {
            const formsData = result.data.flatMap(({ form }) => form);
            //  const subFormsData = result.data.flatMap(({ subForms }) => subForms);
            const formWithSubForms = result.data.flatMap(
              ({ form, subForms }) => ({
                form: {
                  ...form,
                  subForms: subForms.filter(
                    subForm => subForm.formId === form.formId
                  )
                }
              })
            );

            updateState({
              listOfForms: sortCategories(formsData, 'formName'),
              //  listOfSubForms: sortCategories(subFormsData, 'subFormName'),
              // listOfAllSubForms: subFormsData,
              listOfFormSubForm: formWithSubForms
            });
          } else {
            setFieldValue('formId', '');
            updateState({
              listOfForms: [],
              listOfSubForms: []
            });
          }
        }
      } finally {
        updateState({ isLoading: false });
      }
    },
    [handleGetDashboardForms]
  );

  const loadMetadata = useCallback(async id => {
    updateState({ isLoading: true, metadataId: id });

    const response = await handleGetMetadata(id);
    const result = handleResponse(response);
    if (result.isSuccess) {
      const { data } = result;

      await loadForms(data.dataDomainId, data.year);

      updateState({
        dataDomainId: data.dataDomainId || '',
        year: data.year || '',
        formId: data.formId || '',
        subFormId: data.subformId || '',
        category1: data.category1Id || '',
        category2: data.category2Id || '',
        category3: data.category3Id || '',
        segmentType: data.dataSegmentType || '',
        earnedScore: data.earnedScore || '',
        dataEvalMinScore: data.minPointsReq || ''
      });
    }

    updateState({ isLoading: false });
  }, []);

  const initializeMetadata = useCallback(async () => {
    updateState({ isLoading: true });
    try {
      const [domainsRes, yearsRes, segmentRes] = await Promise.all([
        handleGetDomains(),
        handleGetYears(),
        handleGetSegmentTypes()
      ]);

      updateState({
        listOfDataDomains: handleResponse(domainsRes).data || [],
        listOfYears: handleResponse(yearsRes).data || [],
        listOfSegmentTypes: handleResponse(segmentRes).data || []
      });

      const path = window.location.pathname;
      const id = path.substring(path.lastIndexOf('/') + 1);
      if (id && id > 0) {
        await loadMetadata(id);
      }
    } catch (error) {
      const errorMsg = handleCatch(error);
      updateState({
        showDownloadMessage: errorMsg || APP_LABELS.ERROR_MESSAGE,
        showDownloadStatus: true
      });
    } finally {
      updateState({ isLoading: false });
    }
  }, []);

  const updateCategoryMappings = (data, setFieldValue) => {
    setCategoryMappingValidation(data);
    const nestedCategories = [];
    data.forEach(
      ({
        category1Id,
        category1Name,
        category2Id,
        category2Name,
        category3Id,
        category3Name
      }) => {
        // Find or create category1
        // eslint-disable-next-line no-shadow
        let category1 = nestedCategories.find(
          cat1 => cat1.category1Id === category1Id
        );
        if (!category1) {
          category1 = {
            category1Id,
            category1Name,
            category2: []
          };
          nestedCategories.push(category1);
        }

        // Find or create category2 under category1
        // eslint-disable-next-line no-shadow
        let category2 = category1.category2.find(
          cat2 => cat2?.category2Id === category2Id
        );
        if (!category2) {
          category2 = {
            category2Id,
            category2Name,
            category3: []
          };
          category1.category2.push(category2);
        }

        // Find or create category3 under category2
        // eslint-disable-next-line no-shadow
        let category3 = category2.category3.find(
          cat3 => cat3.category3Id === category3Id
        );
        if (!category3) {
          category3 = {
            category3Id,
            category3Name
          };
          category2.category3.push(category3);
        }
      }
    );

    updateState({
      listOfCategories: nestedCategories,
      listOfCategory1: sortCategories(nestedCategories, 'category1Name')
    });
    if (nestedCategories?.length === 0) {
      setFieldValue('category1Id', '');
      updateState({
        listOfCategory2: [],
        listOfCategory3: []
      });
    }
  };

  const loadSubForms = useCallback(
    _formId => {
      const subForms = listOfFormSubForm.find(
        c => c.form.formId.toString() === _formId.toString()
      )?.form?.subForms;
      updateState({ listOfSubForms: sortCategories(subForms, 'subFormName') });
    },
    [listOfFormSubForm]
  );

  const loadCategories = useCallback(
    async (dataDomainId, year, _formId, _subFormId, setFieldValue) => {
      if (!dataDomainId || !year || !_formId || !_subFormId) {
        return;
      }
      const response = await handleGetCategoryMappings(
        dataDomainId,
        year,
        _formId,
        _subFormId
      );
      const result = handleResponse(response);
      updateCategoryMappings(result.data, setFieldValue);
    },
    [listOfFormSubForm]
  );

  const loadCategory2 = useCallback(
    _category1 => {
      const category2List = listOfCategory1.find(
        c => c.category1Id.toString() === _category1.toString()
      )?.category2;
      updateState({
        listOfCategory2: sortCategories(category2List, 'category2Name')
      });
    },
    [listOfCategories]
  );

  const loadCategory3 = useCallback(
    _category2 => {
      const category3List = listOfCategory2?.find(
        c => c?.category2Id?.toString() === _category2?.toString()
      )?.category3;
      updateState({
        listOfCategory3: sortCategories(category3List, 'category3Name')
      });
    },
    [listOfCategory2]
  );

  const returnEvalName = (
    evalGroup,
    category1Name,
    category2Name,
    category3Name
  ) => {
    if (evalGroup == 3 && category3Name) return category3Name;
    if (evalGroup == 2 && category2Name) return category2Name;
    if (evalGroup == 1 && category1Name) return category1Name;
  };

  const handleEvalDataByIds = async (
    values,
    setFieldValue,
    evalGroupId,
    subformId,
    fieldName
  ) => {
    const groupName = evalGroupList?.find(
      group => group?.value?.toString() === evalGroupId?.toString()
    )?.name;

    const payload = {
      domainId: values?.dataDomainId,
      year: values?.year,
      formId: values?.formId,
      subformId: fieldName === 'SubFormField' ? subformId : values?.subformId,
      evalGroup: groupName,
      categoryMappingId: 1
    };

    try {
      const response = await handlePostEvalDataByIds(payload);
      const result = handleResponse(response);
      if (result?.isSuccess) {
        setScoringData(result?.data);
      } else {
        handleScoreFields(null, setFieldValue);
      }
    } catch (error) {
      handleScoreFields(null, setFieldValue);
    }
  };

  const handleFormSubmit = async (values, { resetForm, setFieldValue }) => {
    const {
      category2Id,
      category3Id,
      formId,
      year,
      subformId,
      category1Id,
      dataSegmentType,
      minPointsReq,
      earnedScore,
      evalGroup,
      dataDomainId,
      category3Name,
      category2Name,
      category1Name
    } = values;

    setEvalGroupErrMsg('');
    if (
      evalGroup === '1' &&
      dataSegmentType === '1' &&
      [Boolean(category1Id), Boolean(category2Id), Boolean(category3Id)].filter(
        Boolean
      ).length >= 2
    ) {
      // validation rule 3
      setEvalGroupErrMsg(
        '* Cannot evaluate the selected Group Type from a Parent Category in a multi-level hierarchy.'
      );
      return;
    }
    if (
      evalGroup === '1' &&
      [Boolean(category1Id), Boolean(category2Id), Boolean(category3Id)].filter(
        Boolean
      ).length >= 3
    ) {
      // validation rule 4
      setEvalGroupErrMsg(
        '* Cannot evaluate the selected Group Type from a Parent Category in a multi-level hierarchy.'
      );
      return;
    }

    if (
      evalGroup === '2' &&
      (dataSegmentType === '2' || dataSegmentType === '3') &&
      [Boolean(category1Id), Boolean(category2Id), Boolean(category3Id)].filter(
        Boolean
      ).length === 2
    ) {
      // validation rule 7
      setEvalGroupErrMsg(
        '* Cannot evaluate the selected Group Type from a leaf node in multiple level hierarchy.'
      );
      return;
    }

    if (
      evalGroup === '2' &&
      // dataSegmentType !== 'SINGLE_CHOICE' &&
      [Boolean(category1Id), Boolean(category2Id), Boolean(category3Id)].filter(
        Boolean
      ).length === 1
    ) {
      // validation rule 5
      setEvalGroupErrMsg('* Invalid Evaluation Group selected.');
      return;
    }

    if (
      evalGroup === '3' &&
      (dataSegmentType === '2' || dataSegmentType === '3') &&
      [Boolean(category1Id), Boolean(category2Id), Boolean(category3Id)].filter(
        Boolean
      ).length === 3
    ) {
      // validation rule 12
      setEvalGroupErrMsg(
        '* Cannot evaluate the selected Group Type from a leaf node in multiple level hierarchy.'
      );
      return;
    }
    if (
      evalGroup === '3' &&
      [Boolean(category1Id), Boolean(category2Id), Boolean(category3Id)].filter(
        Boolean
      ).length === 2
    ) {
      // validation rule 10
      setEvalGroupErrMsg('* Invalid Evaluation Group selected.');
      return;
    }
    if (
      evalGroup === '3' &&
      [Boolean(category1Id), Boolean(category2Id), Boolean(category3Id)].filter(
        Boolean
      ).length === 1
    ) {
      // validation rule 9
      setEvalGroupErrMsg('* Invalid Evaluation Group selected.');
      return;
    }

    const resultMatchCategory = categoryMappingValidation?.find(obj => {
      const matchCategory1 =
        obj?.category1Id ===
        (category1Id ? Number(category1Id) : obj?.category1Id);
      const matchCategory2 =
        obj?.category2Id === (category2Id ? Number(category2Id) : null);
      const matchCategory3 =
        obj?.category3Id === (category3Id ? Number(category3Id) : null);
      return matchCategory1 && matchCategory2 && matchCategory3;
    });

    if (!resultMatchCategory) {
      setEvalGroupErrMsg('* Invalid Categories combination selected.');
      return;
    }

    setEvalGroupErrMsg('');
    const evalGroupTypeName = listOfSegmentTypes?.find(
      groupObj =>
        groupObj?.evalTypeId?.toString() === dataSegmentType?.toString()
    )?.name;

    const payload = {
      dataDomainId,
      year,
      evalGroupName: returnEvalName(
        evalGroup,
        category1Name,
        category2Name,
        category3Name
      ),
      evalGroupLevel: values?.evalGroup ? `Category ${values?.evalGroup}` : '',
      evalTypeId: dataSegmentType,
      evalTypeName: evalGroupTypeName,
      minPointsReq: values?.minPointsReq || null,
      earnedScore: values?.earnedScore,
      categoryMappingId: resultMatchCategory?.categoryMappingId
    };

    if (!minPointsReq) delete payload.minPointsReq;
    setIsLoading(true);

    try {
      const response = await handleAddMetadataScoring(payload);
      const result = handleResponse(response);
      if (result?.isSuccess) {
        resetForm();
        updateState({ showSuccess: true });
        handleScoreFields(null, setFieldValue);
        setFieldValue('formId', formId);
        setFieldValue('subformId', subformId);
        setFieldValue(
          'formName',
          listOfForms?.find(res => res?.formId === formId)?.formName || ''
        );
        setFieldValue(
          'subformName',
          listOfSubForms?.find(res => res?.subFormId === subformId)?.subFormName
        );
        setFieldValue('dataDomainId', dataDomainId);
        setFieldValue('year', year);
        setFieldValue('evalGroup', 'All');
        setShowDataScroingForm(false);
        handleEvalDataByIds(values, setFieldValue, 'All');
        // getEvalDataBySubform(values, subformId);
        // setScoringData([]);
      } else {
        updateState({
          showFailure: true,
          errorMessage: result?.message
        });
      }
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const clearCategoriesData = setFieldValue => {
    setFieldValue('category1Id', '');
    setFieldValue('category1Name', '');
    setFieldValue('category2Id', '');
    setFieldValue('category2Name', '');
    setFieldValue('category3Id', '');
    setFieldValue('category3Name', '');
  };

  const handleScoreFields = (data, setFieldValue) => {
    setSelectedScoreData(data);
    // setFieldValue('minPointsReq', data?.minPointsReq || '');
    setFieldValue('earnedScore', data?.earnedScore || '');
    // setFieldValue(
    //   'evalGroup',
    //   data?.evalGroup?.replace('Category', '')?.trim() || ''
    // );
    setFieldValue('dataSegmentType', data?.evalGroupType || '');
    setEvalGroupErrMsg('');
  };

  const handleForm = (value, setFieldValue) => {
    if (value) {
      loadSubForms(value);
      setFieldValue('formId', value);
      setFieldValue('subformId', '');
      clearCategoriesData(setFieldValue);
      const name = listOfForms?.find(res => res.formId == value)?.formName;
      setFieldValue('formName', name);
    }
    setScoringData([]);
    handleScoreFields(null, setFieldValue);
  };

  const handleCategory1List = (value, setFieldValue, values) => {
    if (value) {
      setFieldValue('category1Id', value);
      loadCategory2(value);
      const name = listOfCategory1?.find(
        res => res.category1Id == value
      )?.category1Name;
      setFieldValue('category1Name', name);
      if (values?.evalGroup !== '') {
        handleEvalDataByIds(
          { ...values, category1: value },
          setFieldValue,
          values.evalGroup
        );
      }
    } else {
      handleScoreFields(null, setFieldValue);
    }
  };

  const handleCategory2List = (value, setFieldValue, values) => {
    if (value) {
      setFieldValue('category2Id', value);
      const name = listOfCategory2?.find(
        res => res.category2Id == value
      )?.category2Name;
      setFieldValue('category2Name', name);
      loadCategory3(value);
      if (values?.evalGroup !== '') {
        handleEvalDataByIds(
          { ...values, category2: value },
          setFieldValue,
          values.evalGroup
        );
      }
    }
  };

  const handleCategory3List = (value, setFieldValue, values) => {
    if (value) {
      setFieldValue('category3Id', value);
      const name = listOfCategory3?.find(
        res => res.category3Id == value
      )?.category3Name;
      setFieldValue('category3Name', name);
      if (values?.evalGroup !== '') {
        handleEvalDataByIds(
          { ...values, category3: value },
          setFieldValue,
          values.evalGroup
        );
      }
    }
  };

  const handledataDomain = (value, setFieldValue) => {
    setFieldValue('year', '');
    setFieldValue('formId', '');
    setFieldValue('subformId', '');
    setFieldValue('category1Id', '');
    setFieldValue('category2Id', '');
    setFieldValue('category3Id', '');
    // setFieldValue('evalGroup', '');
    setFieldValue('dataSegmentType', '');
    setFieldValue('minPointsReq', '');
    setFieldValue('earnedScore', '');
    setScoringData([]);

    if (value) {
      setFieldValue('dataDomainId', value);
      const name = listOfDataDomains?.find(
        res => res.dataDomainId == value
      )?.dataDomainName;
      setFieldValue('dataDomainName', name);
    }
  };

  const handleYearDropdownChange = (yearValueset, setFieldValue) => {
    setFieldValue('year', yearValueset);
    if (!showDataScroingForm) {
      setFieldValue('formId', '');
      setFieldValue('subformId', '');
      setFieldValue('category1Id', '');
      setFieldValue('category2Id', '');
      setFieldValue('category3Id', '');
      setFieldValue('dataSegmentType', '');
      setFieldValue('minPointsReq', '');
      setFieldValue('earnedScore', '');
    }
    setScoringData([]);
  };

  const handleSubForm = (formValues, value, setFieldValue) => {
    setFieldValue('evalGroup', 'All');
    if (value) {
      setFieldValue('subformId', value);
      clearCategoriesData(setFieldValue);
      const name = listOfSubForms?.find(
        res => res.subFormId == value
      )?.subFormName;
      setFieldValue('subformName', name);
    }
    handleEvalDataByIds(
      { ...formValues },
      setFieldValue,
      'All',
      value,
      'SubFormField'
    );
  };

  const getEvalDataBySubform = async (values, subformId) => {
    const { dataDomainId, year, formId } = values;
    try {
      const response = await handleGetEvalDataBySubform(
        dataDomainId,
        year,
        formId,
        subformId
      );
      const result = handleResponse(response);
      if (result.isSuccess) {
        setScoringData(result.data);
      } else {
        setScoringData([]);
      }
    } catch (error) {
      setScoringData([]);
    }
  };

  useEffect(() => {
    initializeMetadata();
    window.scrollTo(0, 0);
  }, [initializeMetadata]);

  const handleEdit = item => {
    setSelectedRow(item.row.original);
  };

  const handleChange = (e, setFieldValue, values) => {
    setFieldValue('evalGroup', e.target.value);
    if (e.target.value?.toString() === '1') {
      setFieldValue('category2Id', '');
      setFieldValue('category3Id', '');
    }
    if (e.target.value?.toString() === '2') {
      setFieldValue('category3Id', '');
    }
    handleEvalDataByIds(values, setFieldValue, e.target.value);
  };

  const handleEvalGroupFieldChanges = (e, setFieldValue, values) => {
    setFieldValue('evalGroup', e);
    handleEvalDataByIds({ ...values }, setFieldValue, e);
  };

  const handleBackBtn = () => {
    setEvalGroupErrMsg('');
    if (showDataScroingForm) {
      setShowDataScroingForm(false);
    } else {
      setRedirectValue('/metadata-manager/dashboard');
      setRedirect(true);
    }
  };

  return (
    <BlockUi
      blocking={isLoading || isloading}
      message="Loading, please wait"
      loader={<Loader active type="semi-circle-spin" />}
    >
      <div className="ymca-wrapper manage-metadata-page">
        <main className="page-container">
          <div className="container">
            <div className="d-flex justify-content-between">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb ymca-breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/metadata-manager/dashboard">
                      <span>Dashboard</span>
                    </Link>
                    <img
                      src={BreadcrumbIcon}
                      className="breadcrumb-icon"
                      alt=""
                    />
                  </li>
                  <li className="breadcrumb-item active">
                    {showDataScroingForm
                      ? 'Add Scoring Group'
                      : 'Data Scoring Metadata'}
                  </li>
                </ol>
              </nav>
              <Link
                className="ymca-title-link"
                role="button"
                onClick={() => handleBackBtn()}
                // to="/metadata-manager/dashboard"
              >
                <img src={BackIcon} className="me-1 mb-2" alt="back-icon" />
                Back
              </Link>
            </div>
            <h5 className="ymca-sub-heading mb-0">
              {showDataScroingForm
                ? 'Add Scoring Group'
                : 'Data Scoring Metadata'}
            </h5>
          </div>
          <hr />
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
            enableReinitialize
          >
            {({ setFieldValue, values }) => {
              const { dataDomainId, year, formId, subformId } = values;

              useEffect(() => {
                if (dataDomainId && year) {
                  // setFieldValue('category1Id', '');
                  loadForms(dataDomainId, year, setFieldValue);
                }
              }, [dataDomainId, year]);

              useEffect(() => {
                if (dataDomainId && year && formId && subformId)
                  loadCategories(
                    dataDomainId,
                    year,
                    formId,
                    subformId,
                    setFieldValue
                  );
              }, [dataDomainId, year, formId, subformId]);
              return (
                <>
                  <Form autoComplete="off" className="ymca-wrapper">
                    <div className="container border-wrapper">
                      <div className="row ymca-form">
                        <div className="col-12 col-md-6 col-lg-3">
                          <div className="form-group">
                            <label htmlFor="ddlDoamins" className="form-label">
                              Data Domain{' '}
                              <span className="text-mandatory ms-1">*</span>
                            </label>
                            <Field
                              as="select"
                              name="dataDomainId"
                              className="form-select"
                              aria-label="Default select example"
                              onChange={e =>
                                handledataDomain(e.target.value, setFieldValue)
                              }
                            >
                              <option value=""> Select </option>
                              {listOfDataDomains.map(item => (
                                <option value={item.dataDomainId}>
                                  {item.dataDomainName}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              component={TextError}
                              name="dataDomainId"
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-lg-3">
                          <div className="form-group">
                            <label htmlFor="ddlYears" className="form-label">
                              Year<span className="text-mandatory ms-1">*</span>
                            </label>
                            <Field
                              as="select"
                              name="year"
                              className="form-select"
                              aria-label="Default select example"
                              onChange={e =>
                                handleYearDropdownChange(
                                  e.target.value,
                                  setFieldValue
                                )
                              }
                            >
                              <option value=""> Select</option>
                              {listOfYears.map(item => (
                                <option value={item}>{item}</option>
                              ))}
                            </Field>
                            <ErrorMessage component={TextError} name="year" />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-lg-3">
                          <div className="form-group">
                            <label htmlFor="ddlForms" className="form-label">
                              Form Name
                              <span className="text-mandatory ms-1">*</span>
                            </label>
                            <Field
                              as="select"
                              name="formId"
                              className="form-select"
                              aria-label="Default select example"
                              onChange={e =>
                                handleForm(e.target.value, setFieldValue)
                              }
                            >
                              <option value=""> Select </option>
                              {listOfForms.map(item => (
                                <option
                                  value={item.formId}
                                  formId={item.formId}
                                >
                                  {item.formName}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage component={TextError} name="formId" />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-lg-3">
                          <div className="form-group">
                            <label htmlFor="ddlSubForms" className="form-label">
                              Sub Form Name
                              <span className="text-mandatory ms-1">*</span>
                            </label>
                            <Field
                              as="select"
                              name="subformId"
                              className="form-select"
                              aria-label="Default select example"
                              onChange={e => {
                                handleSubForm(
                                  values,
                                  e.target.value,
                                  setFieldValue
                                );
                                handleScoreFields(null, setFieldValue);
                                // handleEvalData(values, setFieldValue);
                              }}
                            >
                              <option value=""> Select </option>
                              {listOfSubForms &&
                                listOfSubForms?.map(item => (
                                  <option value={item.subFormId}>
                                    {item.subFormName}
                                  </option>
                                ))}
                            </Field>
                            <ErrorMessage
                              component={TextError}
                              name="subformId"
                            />
                          </div>
                        </div>
                        {showDataScroingForm && (
                          <>
                            <div className="col-12 col-md-6 col-lg-3">
                              <div className="form-group">
                                <label
                                  htmlFor="ddlCategroy1"
                                  className="form-label"
                                >
                                  Category 1
                                  <span className="text-mandatory ms-1">*</span>
                                </label>
                                <Field
                                  as="select"
                                  name="category1Id"
                                  className="form-select"
                                  aria-label="Default select example"
                                  onChange={e =>
                                    handleCategory1List(
                                      e.target.value,
                                      setFieldValue,
                                      values
                                    )
                                  }
                                >
                                  <option value=""> Select </option>
                                  {listOfCategory1?.map(item => (
                                    <option value={item.category1Id}>
                                      {item.category1Name}
                                    </option>
                                  ))}
                                </Field>
                                <ErrorMessage
                                  component={TextError}
                                  name="category1Id"
                                />
                              </div>
                            </div>

                            <div className="col-12 col-md-6 col-lg-3">
                              <div className="form-group">
                                <label
                                  htmlFor="ddlCategroy2"
                                  className="form-label"
                                >
                                  Category 2
                                </label>
                                <Field
                                  as="select"
                                  name="category2Id"
                                  className="form-select"
                                  aria-label="Default select example"
                                  onChange={e =>
                                    handleCategory2List(
                                      e.target.value,
                                      setFieldValue,
                                      values
                                    )
                                  }
                                >
                                  <option value=""> Select </option>
                                  {listOfCategory2 &&
                                    listOfCategory2
                                      ?.filter(res => res?.category2Id)
                                      .map(item => (
                                        <option value={item.category2Id}>
                                          {item.category2Name}
                                        </option>
                                      ))}
                                </Field>
                                <ErrorMessage
                                  component={TextError}
                                  name="category2Id"
                                />
                              </div>
                            </div>

                            <div className="col-12 col-md-6 col-lg-3">
                              <div className="form-group">
                                <label
                                  htmlFor="ddlCategroy3"
                                  className="form-label"
                                >
                                  Category 3
                                </label>
                                <Field
                                  as="select"
                                  name="category3Id"
                                  className="form-select"
                                  aria-label="Default select example"
                                  onChange={e => {
                                    handleCategory3List(
                                      e.target.value,
                                      setFieldValue,
                                      values
                                    );
                                  }}
                                >
                                  <option value=""> Select </option>
                                  {listOfCategory3 &&
                                    listOfCategory3
                                      ?.filter(res => res?.category3Id)
                                      ?.map(item => (
                                        <option value={item.category3Id}>
                                          {item.category3Name}
                                        </option>
                                      ))}
                                </Field>
                                <ErrorMessage
                                  component={TextError}
                                  name="category3Id"
                                />
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-3" />
                          </>
                        )}

                        <div className="col-12 col-md-6 col-lg-3">
                          <div className="form-group">
                            <label
                              htmlFor="ddlEvalGroup"
                              className="form-label"
                            >
                              Scoring Group Level
                              <span className="text-mandatory ms-1">*</span>
                            </label>

                            <Field
                              as="select"
                              name="evalGroup"
                              className="form-select"
                              disabled={
                                selectedScoreData &&
                                selectedScoreData?.evalGroup
                              }
                              onChange={e =>
                                handleEvalGroupFieldChanges(
                                  e.target.value,
                                  setFieldValue,
                                  values
                                )
                              }
                            >
                              <option value=""> Select </option>
                              {!showDataScroingForm && (
                                <option value="All">All</option>
                              )}
                              <option value={1}>Category 1</option>
                              <option value={2}>Category 2</option>
                              <option value={3}>Category 3</option>
                            </Field>
                            <ErrorMessage
                              component={TextError}
                              name="evalGroup"
                            />
                          </div>
                        </div>
                        {showDataScroingForm && (
                          <>
                            <div className="col-12 col-md-6 col-lg-3">
                              <div className="form-group">
                                <label
                                  htmlFor="ddlEvalGroupType"
                                  className="form-label"
                                >
                                  Scoring Group Type
                                  <span className="text-mandatory ms-1">*</span>
                                </label>
                                <Field
                                  as="select"
                                  name="dataSegmentType"
                                  className="form-select"
                                  aria-label="Default select example"
                                >
                                  <option value=""> Select </option>
                                  {listOfSegmentTypes.map(item => (
                                    <option value={item?.evalTypeId}>
                                      {item?.name}
                                    </option>
                                  ))}
                                </Field>
                                <ErrorMessage
                                  component={TextError}
                                  name="dataSegmentType"
                                />
                              </div>
                            </div>

                            <div className="col-12 col-md-6 col-lg-3">
                              <div className="form-group">
                                <label
                                  htmlFor="txtDataPointScore"
                                  className="form-label"
                                >
                                  Min Points Required
                                </label>
                                <Field
                                  type="text"
                                  className="form-control"
                                  id="txtDataPointScore"
                                  name="minPointsReq"
                                  placeholder="Enter"
                                  maxLength={9}
                                />
                                <ErrorMessage
                                  component={TextError}
                                  name="minPointsReq"
                                />
                              </div>
                            </div>

                            <div className="col-12 col-md-6 col-lg-3">
                              <div className="form-group">
                                <label
                                  htmlFor="txtDataEvalMin"
                                  className="form-label"
                                >
                                  Earned Score
                                  <span className="text-mandatory ms-1">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className="form-control"
                                  id="txtDataEvalMin"
                                  name="earnedScore"
                                  maxLength={9}
                                  placeholder="Enter"
                                />
                                <ErrorMessage
                                  component={TextError}
                                  name="earnedScore"
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </div>

                      {metadataId && (
                        <div className="updated-by-section">
                          <div>
                            Last Updated By:
                            <b className="ms-1">
                              {state.updatedBy || state.createdBy}
                            </b>
                          </div>
                          <div className="ms-3">
                            On:
                            <b className="ms-1">
                              {moment
                                .utc(state.updatedDttm)
                                .local()
                                .format('DD MMM YYYY, hh:mm a') ||
                                moment
                                  .utc(state.createdDttm)
                                  .local()
                                  .format('DD MMM YYYY, hh:mm a')}
                            </b>
                          </div>
                        </div>
                      )}

                      {evalGroupErrMsg && (
                        <div className="errorNote">{evalGroupErrMsg}</div>
                      )}

                      <div className="d-flex justify-content-end mt-4">
                        {showDataScroingForm && (
                          <>
                            {/* <Link
                              className="btn ymca-primary-outline-btn"
                              type="button"
                              to="/metadata-manager/dashboard"
                            >
                              Cancel
                            </Link> */}
                            <button
                              className="btn ymca-primary-outline-btn"
                              type="button"
                              onClick={() => setShowDataScroingForm(false)}
                            >
                              Cancel
                            </button>
                            <button
                              className="btn ymca-primary-btn ms-3"
                              type="submit"
                            >
                              Submit
                            </button>
                          </>
                        )}

                        {!showDataScroingForm && (
                          <button
                            className="btn ymca-primary-btn ms-3"
                            type="button"
                            onClick={() => setShowDataScroingForm(true)}
                          >
                            Add Scoring Group
                          </button>
                        )}
                      </div>
                    </div>
                  </Form>
                  <ScoringViewDetails
                    row={selectedRow}
                    getEvalDataBySubform={getEvalDataBySubform}
                    handleEvalDataByIds={handleEvalDataByIds}
                    scoreValues={values}
                    listOfSegmentTypes={listOfSegmentTypes}
                  />
                </>
              );
            }}
          </Formik>
          {!showDataScroingForm && (
            <>
              <hr />
              <div className="container">
                <div className="row align-items-center mt-3 mb-3">
                  <div className="col-lg-12">
                    <div className="ymca-sub-heading ps-3">
                      Subform Scoring Groups
                    </div>
                  </div>
                </div>
                <Table
                  rows={scoringData}
                  headers={METADATA_SCORING_COLUMNS({ handleEdit })}
                />
              </div>
            </>
          )}
        </main>
      </div>

      <CustomModal
        show={showSuccess}
        type={APP_LABELS.SUCCESS}
        message={META_DATA.ADD_META_DATA_SUCCESS}
        submitButtonName="Ok"
        onSubmit={() => updateState({ showSuccess: false })}
      />

      <CustomModal
        show={showFailure}
        type={APP_LABELS.ERROR}
        message={errorMessage || APP_LABELS.ERROR_MESSAGE}
        submitButtonName="Ok"
        onSubmit={() => updateState({ showFailure: false })}
      />
      {redirect && <Redirect to={redirectValue} />}
    </BlockUi>
  );
};

ScoringMetaData.propTypes = {
  handleGetDomains: PropTypes.func.isRequired,
  handleGetYears: PropTypes.func.isRequired,
  handleGetDashboardForms: PropTypes.func.isRequired,
  handleGetCategoryMappings: PropTypes.func.isRequired,
  handleGetSegmentTypes: PropTypes.func.isRequired,
  handleAddMetadata: PropTypes.func.isRequired,
  handleUpdateMetadata: PropTypes.func.isRequired,
  handleAddMetadataScoring: PropTypes.func.isRequired,
  handleGetEvalDataBySubform: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  handleGetDomains: getDomains,
  handleGetYears: getYears,
  handleGetDashboardForms: getDashboardForms,
  handleGetSegmentTypes: getSegmentTypes,
  handleAddMetadata: addMetadata,
  handleUpdateMetadata: updateMetadata,
  handleGetCategoryMappings: getCategoryMappingBySubForms,
  handleAddMetadataScoring: addMetadataScoring,
  handleGetEvalDataBySubform: getEvalDataBySubform,
  handlePostEvalDataByIds: postEvalDataByIds
};

export default connect(null, mapDispatchToProps)(ScoringMetaData);
