import React, { useState, useEffect } from 'react';
import BackIcon from '@static/images/back-icon.svg';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders';
import helpicon from '@static/images/caretdoubleright.svg';
import { METADATA_DATA_SCORING_COLUMNS } from './column';
import { DataScoringAssociationSelector } from '@redux/metadata-manager/data-scoring/selectors';
import { connect } from 'react-redux';
import BreadcrumbIcon from '@static/images/breadcrumb-icon.svg';
import moment from 'moment';
import {
  getDomains,
  getYears
} from '@redux/metadata-manager/dashboard/actions';
import Table from '../categories/table';
import Help from '@modules/shared/components/reusable-components/help';
import {
  getDataScoringAssociation,
  getDataScoringAssociationList
} from '@redux/metadata-manager/data-scoring/actions';
const MetaDataManagerDataScoring = ({
  handleGetDomains,
  handleGetYears,
  handleGetDataScoringAssociation,
  handleGeAssociationList
}) => {
  const [loader, setLoader] = useState(false);
  const [selectedAssociation, setSelectedAssociation] = useState(null);
  const [selectedDataDomain, setSelectedDataDomain] = useState(null);
  const [help, setHelp] = useState(false);
  const [selectedYear, setSelectedYear] = useState(null);
  const [dataDomains, setDataDomains] = useState([]);
  const [years, setYears] = useState([]);
  const [associations, setAssociations] = useState([]);
  const [tableData, setTableData] = useState([]);

  const getAssociations = async () => {
    await handleGetDataScoringAssociation(moment().year()).then(res => {
      if (res) {
        setTableData(res?.data?.data);
      }
    });
  };

  useEffect(async () => {
    window.scrollTo(0, 0);
    setLoader(true);
    await handleGetDomains().then(res => {
      if (res) {
        setDataDomains(res?.data?.data);
        if (res?.data?.data?.length > 0) {
          setSelectedDataDomain(res?.data?.data[0]?.dataDomainName);
        }
      }
    });
    await handleGetYears().then(res => {
      if (res) {
        setYears(res?.data?.data);
        if (res?.data?.data?.length > 0) {
          setSelectedYear(moment().year());
        }
      }
    });
    await handleGeAssociationList().then(res => {
      if (res) {
        setAssociations(res?.data?.data);
        if (res?.data?.data?.length > 0) {
          setSelectedAssociation('all');
        }
      }
    });
    getAssociations();
    setLoader(false);
  }, []);
  const handleShowHelp = () => {
    setHelp(true);
  };
  const hideShowHelp = () => {
    setHelp(false);
  };
  const renderTabledata = tableData?.map(data => {
    const location1 = {
      pathname: '/metadata-manager/datascoring/forms',
      state: {
        Year: selectedYear,
        DataDomain: dataDomains?.find(
          res => res?.dataDomainName === selectedDataDomain
        )?.dataDomainId,
        Association: data.associationNumber,
        dataDomainName: selectedDataDomain,
        associationName: data.associationName,
        associationNumber: data.associationNumber
      }
    };
    return {
      Year: selectedYear,
      DataDomain: selectedDataDomain,
      Association: data.associationName,
      DetailPage: location1,
      totalScore: data?.totalScore,
      associationNumber: data.associationNumber
    };
  });

  const handleApply = () => {
    handleGetDataScoringAssociation(
      selectedYear,
      selectedAssociation === 'all' ? '' : selectedAssociation
    ).then(res => {
      if (res) {
        setTableData(res?.data?.data);
      }
    });
  };

  const handleCancel = () => {
    setSelectedAssociation('all');
    setSelectedDataDomain(dataDomains[0].dataDomainId);
    setSelectedYear(moment().year());
    getAssociations();
  };
  return (
    <BlockUi
      blocking={loader}
      message="Loading, please wait"
      loader={<Loader active type="semi-circle-spin" />}
    >
      <>
        <div className="ymca-wrapper">
          <main className="page-container">
            <button
              type="button"
              className="ymca-help-link text-decoration-none ymca-up-data-help-link  "
              onClick={handleShowHelp}
            >
              HELP{/* */}
              <img src={helpicon} className="img-fluid" alt="caret-icon" />
            </button>
            <div className="container">
              <div className="d-flex justify-content-between">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb ymca-breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/metadata-manager/dashboard">
                        <span>Dashboard</span>
                      </Link>
                      <img
                        src={BreadcrumbIcon}
                        className="breadcrumb-icon"
                        alt=""
                      />
                    </li>
                    <li className="breadcrumb-item active">Data Scoring</li>
                  </ol>
                </nav>
                <Link
                  className="ymca-title-link"
                  to="/metadata-manager/dashboard"
                >
                  <img src={BackIcon} className="me-1 mb-2" alt="back-icon" />{' '}
                  Back
                </Link>
              </div>
              <h5 className="ymca-sub-heading">Data Scoring</h5>
            </div>

            <div className="container">
              <div className="row ymca-form align-items-center">
                <div className="col-12 col-md-6 col-lg-3">
                  <div className="form-group">
                    <label for="Year" className="form-label">
                      Year<span className="text-mandatory ms-1">*</span>
                    </label>
                    <select
                      id="ddlYears"
                      className="form-select"
                      aria-label="Default select year"
                      value={selectedYear}
                      onChange={e => setSelectedYear(e.target.value)}
                    >
                      {years.map(item => (
                        <option value={item}>{item}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="col-12 col-md-6 col-lg-3">
                  <div className="form-group">
                    <label for="Datadomain" className="form-label">
                      Data Domain
                      <span className="text-mandatory ms-1">*</span>
                    </label>
                    <select
                      id="ddlDataDomains"
                      className="form-select"
                      aria-label="Default select example"
                      value={selectedDataDomain}
                      onChange={e => setSelectedDataDomain(e.target.value)}
                    >
                      {dataDomains.map(item => (
                        <option value={item.dataDomainName}>
                          {item.dataDomainName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group">
                    <label for="Datadomain" className="form-label">
                      Association
                    </label>
                    <select
                      id="ddlAssociations"
                      className="form-select"
                      aria-label="Default select association"
                      value={selectedAssociation}
                      onChange={e => setSelectedAssociation(e.target.value)}
                    >
                      <option value="all">All</option>
                      {associations?.map(item => (
                        <option value={item.associationNumber}>
                          {item.associationName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="col-12 col-md-6 col-lg-2">
                  <div className="d-flex justify-content-end">
                    <button
                      className="btn ymca-primary-btn"
                      type="button"
                      onClick={handleApply}
                    >
                      Apply
                    </button>
                    <button
                      className="btn ymca-primary-outline-btn ms-3"
                      type="button"
                      onClick={handleCancel}
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="container">
              <div className="ymca-section-heading mb-3">
                {' '}
                Total Data Scoring
              </div>
            </div>

            {tableData && (
              <Table
                rows={renderTabledata}
                headers={METADATA_DATA_SCORING_COLUMNS()}
                showpagination
              />
            )}
          </main>
          {help && <Help hidePopup={hideShowHelp} keyValue="data-scoring" />}
        </div>
      </>
    </BlockUi>
  );
};
MetaDataManagerDataScoring.propTypes = {
  handleGetDomains: PropTypes.func.isRequired,
  handleGetYears: PropTypes.func.isRequired,
  handleGetDataScoringAssociation: PropTypes.func.isRequired,
  handleGeAssociationList: PropTypes.func.isRequired,
  dataScoringAssociations: PropTypes.objectOf(PropTypes.any).isRequired
};
const mapStateToProps = state => ({
  dataScoringAssociations: DataScoringAssociationSelector(state)
});

const mapDispatchToProps = {
  handleGetDomains: getDomains,
  handleGetYears: getYears,
  handleGetDataScoringAssociation: getDataScoringAssociation,
  handleGeAssociationList: getDataScoringAssociationList
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MetaDataManagerDataScoring);
