import React from 'react';
import { Link } from 'react-router-dom';

export const COLUMN = [
  {
    Header: 'Year',
    accessor: 'Year',
    className: 'notranslate'
  },
  {
    Header: 'Data Domain',
    accessor: 'DataDomain',
    className: 'notranslate'
  },
  {
    Header: 'Association',
    accessor: 'Association',
    className: 'notranslate'
  },
  {
    Header: '',
    accessor: 'ViewForms'
  }
];

export const METADATA_DATA_SCORING_COLUMNS = () => [
  {
    Header: 'Year',
    accessor: 'Year',
    className: 'notranslate'
  },
  {
    Header: 'Data Domain',
    accessor: 'DataDomain',
    className: 'notranslate'
  },
  {
    Header: 'Association No.',
    accessor: 'associationNumber',
    className: 'notranslate'
  },
  {
    Header: 'Association',
    accessor: 'Association',
    className: 'notranslate'
  },
  {
    Header: 'Total Score',
    accessor: 'totalScore',
    className: 'notranslate'
  },
  {
    Header: 'Actions',
    accessor: 'DetailPage',
    Cell: v => (
      <>
        <div className="d-flex">
          <Link
            to={v.value}
            className="table-link "
            onClick={() =>
              sessionStorage.setItem('metaData_categoryItem', v.value)
            }
          >
            View Forms
          </Link>
        </div>
      </>
    )
  }
];
