import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders';
import BackIcon from '@static/images/back-icon.svg';
import get from 'lodash.get';
import { Link } from 'react-router-dom';
import Multiselect from 'multiselect-react-dropdown';
import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';
import helpicon from '@static/images/caretdoubleright.svg';
import BreadcrumbIcon from '@static/images/breadcrumb-icon.svg';
import SearchIcon from '@static/images/search-icon.svg';
import CustomModal from '@utilities/CustomModal';
import { APP_LABELS } from '@config/app-config';
import {
  getCategoryMappings,
  deleteCategoryMappings,
  getMappingsByDomineAndYear
} from '@redux/metadata-manager/categories/actions';

import {
  getDomains,
  getYears,
  getDashboardForms
} from '@redux/metadata-manager/dashboard/actions';
import moment from 'moment';
import { handleResponse } from '../shared/index';
import { METADATA_CATEGORIES_COLUMNS } from './table-columns';
import Help from '@modules/shared/components/reusable-components/help';
import Table from './table';
const MetaDataManagerCategories = ({
  handleGetCategories,
  handleDeleteMappings,
  handleGetDomains,
  handleGetYears,
  handleMappingsByDomineAndYear,
  loginUserData,
  handleGetDashboardForms
}) => {
  const [showAttentionPopup, setShowAttentionPopup] = useState(false);
  const [help, setHelp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [allTableData, setAllTableData] = useState([]);
  const [dataDomains, setDataDomains] = useState([]);
  const [years, setYears] = useState([]);
  const [selectedDataDomain, setSelectedDataDomain] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [domain, setDomain] = useState(null);
  const [year, setYear] = useState(null);
  const [forms, setForms] = useState([]);
  const [subForms, setSubForms] = useState([]);
  const [selectedForms, setSelectedForms] = useState([]);
  const [selectedSubForms, setSelectedSubForms] = useState([]);
  const [selectedId, setSelectedId] = useState('');
  const [globalFilter, setGlobalFilter] = useState('');

  const role = get(loginUserData, 'user.role[0]');
  const dataDomainId = dataDomains?.find(
    res => res.dataDomainName === selectedDataDomain
  )?.dataDomainId;

  const handleShowHelp = () => {
    setHelp(true);
  };
  const hideShowHelp = () => {
    setHelp(false);
  };

  const filterTableData = (selectedDataForms, selectedDataSubForms) => {
    if (selectedDataForms.length === 0 && selectedDataSubForms.length === 0) {
      setTableData(allTableData); // Reset to all data if no filters are applied
    } else {
      const filteredData = allTableData.filter(data => {
        const formMatch =
          selectedDataForms.length === 0 ||
          selectedDataForms.some(
            form => form.name.trim() === data.formName?.trim()
          );
        const subFormMatch =
          selectedDataSubForms.length === 0 ||
          selectedDataSubForms.some(
            subForm => subForm.name.trim() === data.subFormName?.trim()
          );
        return formMatch && subFormMatch;
      });
      setTableData(filteredData);
    }
  };

  const handleTableData = async (domain, year) => {
    setDomain(domain);
    setYear(year);
    setSelectedDataDomain(domain);
    setSelectedYear(year);
    setIsLoading(true);
    try {
      const response = await handleMappingsByDomineAndYear(domain, year);
      const result = handleResponse(response);
      if (dataDomainId !== undefined) {
        loadForms(dataDomainId, year);
      }
      if (result?.isSuccess) {
        setTableData(result.data);
        setAllTableData(result.data);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const sortCategories = (data, key) => {
    const sortedData = data.sort((a, b) => {
      if (a[key]?.toLowerCase()?.trim() < b[key]?.toLowerCase()?.trim())
        return -1;
      if (a[key]?.toLowerCase()?.trim() > b[key]?.toLowerCase()?.trim())
        return 1;
      return 0;
    });

    return sortedData;
  };

  const loadForms = useCallback(
    async (domainId, year) => {
      setIsLoading(true);
      const response = await handleGetDashboardForms(domainId, year);
      const result = handleResponse(response);
      if (result.isSuccess) {
        let data = result.data.map(({ form, subForms }) => ({
          name: form.formName,
          id: form.formId,
          subForms
        }));
        setForms(sortCategories(data, 'name'));
      }
      setIsLoading(false);
    },
    [handleGetDashboardForms]
  );

  const initializeCategories = useCallback(async () => {
    setIsLoading(true);
    try {
      const [domains, years] = await Promise.all([
        handleGetDomains(),
        handleGetYears()
      ]);
      const domainRes = handleResponse(domains);
      const yearRes = handleResponse(years);

      if (domainRes.isSuccess && yearRes.isSuccess) {
        setDataDomains(domainRes.data);
        setSelectedDataDomain(domainRes?.data[0].dataDomainName);
        setYears(yearRes.data);
        setSelectedYear(moment().year());
        setDomain(domainRes?.data[0].dataDomainName);
        setYear(moment().year());
        handleTableData(
          sessionStorage.getItem('categorydomain') ||
            domainRes?.data[0].dataDomainName,
          sessionStorage.getItem('categoryyear') || moment().year()
        );
      }
      await loadForms(domainRes?.data[0].dataDomainId, moment().year());
    } finally {
      setIsLoading(false);
    }
  }, [handleGetCategories]);

  const handleFormChange = selectedFormData => {
    if (selectedFormData.length === 0) {
      // If no forms are selected, clear selectedSubForms and reset subForms
      setSelectedSubForms([]);
      setSubForms([]); // Reset to show all subForms
      setTableData(allTableData); // Reset to original table data
      return;
    }

    const childForms = selectedFormData.flatMap(({ subForms }) =>
      subForms.map(a => ({
        name: a.subFormName,
        id: a.subFormId,
        formId: a.formId
      }))
    );

    setSelectedForms(selectedFormData);
    setSubForms(sortCategories(childForms, 'name'));
    const selectedFormIds = selectedFormData.map(c => c.id);
    const isChildExisits = childForms.find(c =>
      selectedFormIds.indexOf(c.formId)
    );
    if (!isChildExisits) {
      setSelectedSubForms([]);
      filterTableData(selectedFormData, []);
    } else {
      filterTableData(selectedFormData, selectedSubForms);
    }
  };

  const handleSubFormChange = data => {
    setSelectedSubForms(data);
    filterTableData(selectedForms, data); // Apply filter
  };

  useEffect(() => {
    initializeCategories();
    window.scrollTo(0, 0);
  }, [initializeCategories]);

  const deleteCategory = async () => {
    setShowAttentionPopup(false);
    setIsLoading(true);

    try {
      const response = await handleDeleteMappings(selectedId);
      const result = handleResponse(response);
      if (result?.isSuccess) {
        initializeCategories();
        setIsLoading(false);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = categoryMappingId => {
    setShowAttentionPopup(true);
    setSelectedId(categoryMappingId);
  };

  const handleEdit = categoryMappingId => {
    const fileds = {
      domine: dataDomains?.find(res => res?.dataDomainName === domain)
        ?.dataDomainId,
      year: year
    };
    sessionStorage.setItem('categoryMappingId', categoryMappingId);
    sessionStorage.setItem('selectedFileds', JSON.stringify(fileds));
  };

  const handleAdd = () => {
    sessionStorage.setItem('categorydomain', selectedDataDomain);
    sessionStorage.setItem('categorydomainId', dataDomainId);
    sessionStorage.setItem('categoryyear', selectedYear);
    sessionStorage.removeItem('categoryMappingId');
    const fileds = {
      domine: dataDomains?.find(res => res?.dataDomainName === domain)
        ?.dataDomainId,
      year: year
    };
    sessionStorage.setItem('selectedFileds', JSON.stringify(fileds));
  };

  return (
    <BlockUi
      blocking={isLoading}
      message="Loading, please wait"
      loader={<Loader active type="semi-circle-spin" />}
    >
      <div className="ymca-wrapper">
        <main className="page-container ymca-data-wrapper">
          <div className="program-admin-wrapper dashboard-wrapper">
            <button
              type="button"
              className="ymca-help-link text-decoration-none ymca-up-data-help-link  "
              onClick={handleShowHelp}
            >
              HELP{/* */}
              <img src={helpicon} className="img-fluid" alt="caret-icon" />
            </button>
            <div className="container">
              <div className="d-flex justify-content-between">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb ymca-breadcrumb">
                    <li className="breadcrumb-item">
                      <Link
                        to="/metadata-manager/dashboard"
                        onClick={() => {
                          sessionStorage.removeItem('categorydomain');
                          sessionStorage.removeItem('categoryyear');
                        }}
                      >
                        <span>Dashboard</span>
                      </Link>
                      <img
                        src={BreadcrumbIcon}
                        className="breadcrumb-icon"
                        alt=""
                      />
                    </li>
                    <li className="breadcrumb-item active">Categories</li>
                  </ol>
                </nav>
                <Link
                  className="ymca-title-link"
                  to="/metadata-manager/dashboard"
                  onClick={() => {
                    sessionStorage.removeItem('categorydomain');
                    sessionStorage.removeItem('categoryyear');
                  }}
                >
                  <img src={BackIcon} className="me-1 mb-2" alt="back-icon" />{' '}
                  Back
                </Link>
              </div>

              <h5 className="ymca-sub-heading mb-0">Categories</h5>
              <div className="col-sm-12 col-md-6 col-lg-6 mt-3">
                <div className="row align-items-center ymca-form justify-content-lg-between">
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <div className="form-group">
                      <label htmlFor="ddlDataDomains" className="form-label">
                        Data Domain
                        <span className="text-mandatory ms-1">*</span>
                      </label>
                      <select
                        id="ddlDataDomains"
                        className="form-select"
                        aria-label="Default select example"
                        value={selectedDataDomain}
                        onChange={e => setSelectedDataDomain(e.target.value)}
                      >
                        {dataDomains.map(item => (
                          <option value={item.dataDomainName}>
                            {item.dataDomainName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <div className="form-group">
                      <label htmlFor="ddlYears" className="form-label">
                        Year<span className="text-mandatory ms-1">*</span>
                      </label>
                      <select
                        id="ddlYears"
                        className="form-select"
                        aria-label="Default select example"
                        value={selectedYear}
                        onChange={e => setSelectedYear(e.target.value)}
                      >
                        {years.map(item => (
                          <option value={item}>{item}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-auto px-lg-0">
                    <button
                      className="btn ymca-primary-btn custom-align"
                      type="button"
                      onClick={() =>
                        handleTableData(selectedDataDomain, selectedYear)
                      }
                    >
                      Apply
                    </button>
                    <button
                      className="btn ymca-primary-outline-btn custom-align ms-3"
                      type="button"
                      onClick={() =>
                        handleTableData(
                          dataDomains[0]?.dataDomainName,
                          moment().year()
                        )
                      }
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </div>
              <hr className="mt-0" />
              <div className="row align-items-center mb-4">
                <div className="col-lg-2">
                  <div className="ymca-section-heading"> Categories</div>
                </div>
                <div className="col-lg-10">
                  <div className="row align-items-center justify-content-end">
                    <div className="col-lg-6 mt-3 mt-lg-0">
                      <div className="search-form-wrapper mb-0">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text"
                              id="basic-addon1"
                            >
                              <img src={SearchIcon} alt="SearchIcon" />
                            </span>
                          </div>
                          <input
                            type="text"
                            className="form-control search-form-control"
                            placeholder="Search based form section and category"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            value={globalFilter}
                            onChange={e => setGlobalFilter(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-auto d-md-flex mt-2 mt-lg-0">
                      <Link
                        className="btn ymca-primary-outline-btn d-md-flex align-items-center ms-0 ms-lg-3"
                        to="/metadata-manager/categories/configure-items"
                        type="button"
                      >
                        Configure Category
                      </Link>
                      {role !== APP_LABELS.YUSA_DATA_VIEWER && (
                        <Link
                          className="btn ymca-primary-btn d-flex align-items-center ms-3"
                          to="/metadata-manager/categories/mapping"
                          type="button"
                          onClick={handleAdd}
                        >
                          Add Category Mapping
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row align-items-center ymca-form mt-3">
                <div className="col-lg-4">&nbsp;</div>
                <div className="col-lg-8">
                  <div className="row align-items-center justify-content-end">
                    <div className="col-md-4 col-lg-4">
                      <div className="form-group">
                        <label htmlFor="ddlFormName" className="form-label">
                          Filter by Form Name
                          <span className="text-mandatory ms-1">*</span>
                        </label>
                        <Multiselect
                          showCheckbox
                          options={forms}
                          hidePlaceholder
                          displayValue="name"
                          selectionLimit={50}
                          selectedValues={selectedForms}
                          onSelect={e => handleFormChange(e)}
                          onRemove={e => handleFormChange(e)}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-4">
                      <div className="form-group">
                        <label htmlFor="datadomain" className="form-label">
                          Filter by Sub Form Name
                          <span className="text-mandatory ms-1">*</span>
                        </label>
                        <Multiselect
                          showCheckbox
                          hidePlaceholder
                          displayValue="name"
                          options={subForms}
                          selectionLimit={50}
                          selectedValues={selectedSubForms}
                          onSelect={e => handleSubFormChange(e)}
                          onRemove={e => handleSubFormChange(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Table
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              rows={tableData}
              headers={METADATA_CATEGORIES_COLUMNS({
                handleDelete,
                domain,
                handleEdit,
                role,
                selectedDataDomain,
                selectedYear,
                dataDomainId
              })}
              showpagination
            />
            <CustomModal
              show={showAttentionPopup}
              type={APP_LABELS.UPLOAD_FILE_ATTENTION}
              message="Are you sure want to delete?"
              submitButtonName="Yes"
              onSubmit={deleteCategory}
              cancelButtonName={APP_LABELS.UPLOAD_DATA_CANCEL}
              onCancel={() => setShowAttentionPopup(false)}
            />
          </div>
        </main>
        {help && <Help hidePopup={hideShowHelp} keyValue="category" />}
      </div>
    </BlockUi>
  );
};

MetaDataManagerCategories.propTypes = {
  handleGetCategories: PropTypes.func.isRequired,
  handleDeleteMappings: PropTypes.func.isRequired,
  handleGetDomains: PropTypes.func.isRequired,
  handleGetYears: PropTypes.func.isRequired,
  handleMappingsByDomineAndYear: PropTypes.func.isRequired,
  loginUserData: PropTypes.node.isRequired,
  handleGetDashboardForms: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
  loginUserData: AssociationAdminLoginDetailsSelector(state)
});

const mapDispatchToProps = {
  handleGetCategories: getCategoryMappings,
  handleMappingsByDomineAndYear: getMappingsByDomineAndYear,
  handleDeleteMappings: deleteCategoryMappings,
  handleGetDomains: getDomains,
  handleGetYears: getYears,
  handleGetDashboardForms: getDashboardForms
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MetaDataManagerCategories);
