import React, { useCallback, useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Loader } from 'react-loaders';
import BlockUi from 'react-block-ui';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import {
  getDashboardForms,
  getDomains,
  getYears
} from '@redux/metadata-manager/dashboard/actions';
import { getCategoryMappingBySubForms } from '@redux/metadata-manager/categories/actions';
import {
  addMetadata,
  getDataPointTypes,
  getMetadata,
  getSegmentTypes,
  updateMetadata
} from '@redux/metadata-manager/manage-metadata/actions';

import BackIcon from '@static/images/back-icon.svg';
import BreadcrumbIcon from '@static/images/breadcrumb-icon.svg';
import { APP_LABELS, META_DATA } from '@config/app-config';
import CustomModal from '@utilities/CustomModal';
import { handleCatch, handleResponse } from '../shared/index';

const MetaDataManagerManageMetadata = ({
  handleGetDomains,
  handleGetYears,
  handleGetSegmentTypes,
  handleGetDataPointTypes,
  handleGetDashboardForms,
  handleGetCategoryMappings,
  handleGetMetadata,
  handleAddMetadata,
  handleUpdateMetadata
}) => {
  const [state, setState] = useState({
    listOfYears: [],
    listOfDataDomains: [],
    listOfForms: [],
    listOfSubForms: [],
    listOfAllSubForms: [],
    listOfCategoryMappings: [],
    listOfSegmentTypes: [],
    listOfDataPointTypes: [],
    listOfCategory1: [],
    listOfCategory2: [],
    listOfCategory3: [],
    listOfFormSubForm: [],
    listOfCategories: [],
    dataDomainId: '',
    year: '',
    formId: '',
    subFormId: '',
    category1: '',
    category2: '',
    category3: '',
    segmentType: '',
    dataPointType: '',
    dataPointScore: '',
    dataEvalMinScore: '',
    qualtricsId: '',
    sourceMapping: '',
    targetMapping: '',
    createdDttm: '',
    createdBy: '',
    updatedDttm: '',
    updatedBy: '',
    formPointsPossible: '',
    isLoading: false,
    showSuccess: false,
    showFailure: false,
    errorMessage: '',
    metadataId: undefined,
    canRedirect: false,
    errors: {} // State for form errors
  });

  const {
    listOfYears,
    listOfDataDomains,
    listOfForms,
    listOfSubForms,
    listOfDataPointTypes,
    listOfCategory1,
    listOfCategory2,
    listOfCategory3,
    listOfFormSubForm,
    listOfCategories,
    dataDomainId,
    year,
    formId,
    subFormId,
    category1,
    category2,
    category3,
    dataPointType,
    dataPointScore,
    qualtricsId,
    sourceMapping,
    targetMapping,
    showSuccess,
    showFailure,
    errorMessage,
    isLoading,
    metadataId,
    canRedirect,
    formPointsPossible
  } = state;

  const formGroup = {
    dataDomainId: {
      validations: {
        required: {
          value: true,
          message: 'Domain is required'
        }
      }
    },
    year: {
      validations: {
        required: {
          value: true,
          message: 'Year is required'
        }
      }
    },
    formId: {
      validations: {
        required: {
          value: true,
          message: 'Form is required'
        }
      }
    },
    subFormId: {
      validations: {
        required: {
          value: true,
          message: 'Subform is required'
        }
      }
    },
    category1: {
      validations: {
        required: {
          value: true,
          message: 'Category 1 is required'
        }
      }
    },
    dataPointType: {
      validations: {
        required: {
          value: true,
          message: 'Data Point Type is required'
        }
      }
    },
    dataPointScore: {
      validations: {
        required: {
          value: true,
          message: 'Data Points is required'
        },
        number: {
          value: true,
          message: 'Data Points must be a valid number'
        }
      }
    },
    formPointsPossible: {
      validations: {
        required: {
          value: true,
          message: 'Form Points Possible is required'
        },
        number: {
          value: true,
          message: 'Form Points Possible must be a valid number'
        }
      }
    },
    sourceMapping: {
      validations: {
        required: {
          value: true,
          message: 'Data Source Mapping is required'
        }
      }
    },
    targetMapping: {
      validations: {
        required: {
          value: true,
          message: 'Data Target Mapping is required'
        }
      }
    }
  };

  // Helper to update state
  const updateState = updates => setState(prev => ({ ...prev, ...updates }));

  const validateField = (field, value) => {
    const errors = [];
    if (formGroup[field]) {
      const { validations } = formGroup[field];

      // Validate the field value
      Object.entries(validations).forEach(([rule, config]) => {
        if (rule === 'required' && config.value && !value) {
          errors.push(config.message);
        }
        if (
          rule === 'number' &&
          config.value &&
          value &&
          !/^\d+$/.test(value)
        ) {
          errors.push(config.message);
        }
      });
    }

    return errors;
  };

  // Form validation logic
  const validateForm = () => {
    const errors = {};
    let formIsValid = true;

    // Validate each field
    Object.keys(formGroup).forEach(field => {
      const value = state[field];
      const fieldErrors = validateField(field, value);

      if (fieldErrors.length > 0) {
        errors[field] = fieldErrors;
        formIsValid = false;
      }
    });

    updateState({ errors });

    return formIsValid;
  };

  const handleInputChange = (field, value) => {
    const fieldErrors = validateField(field, value);
    updateState({
      [field]: value,
      errors: { ...state.errors, [field]: fieldErrors }
    });
  };

  const sortCategories = (data, key) => {
    const sortedData = data?.sort((a, b) => {
      if (a[key]?.toLowerCase()?.trim() < b[key]?.toLowerCase()?.trim())
        return -1;
      if (a[key]?.toLowerCase()?.trim() > b[key]?.toLowerCase()?.trim())
        return 1;
      return 0;
    });
    return sortedData;
  };

  const loadForms = useCallback(
    async (_domainId, _year) => {
      updateState({ isLoading: true });
      try {
        const response = await handleGetDashboardForms(_domainId, _year);
        const result = handleResponse(response);
        if (result.isSuccess) {
          const formsData = result.data.flatMap(({ form }) => form);
          const subFormsData = result.data.flatMap(({ subForms }) =>
            subForms.filter(c => {
              return (
                c.year.toString() === _year.toString() &&
                c.domainId.toString() === _domainId.toString()
              );
            })
          );
          const formWithSubForms = result.data.flatMap(({ form, subForms }) => {
            return {
              form: {
                ...form,
                subForms: subForms.filter(
                  subForm =>
                    subForm.formId.toString() === form.formId.toString() &&
                    subForm.year.toString() === _year.toString() &&
                    subForm.domainId.toString() === _domainId.toString()
                )
              }
            };
          });
          updateState({
            listOfForms: sortCategories(formsData, 'formName'),
            listOfSubForms: sortCategories(subFormsData, 'subFormName'),
            listOfAllSubForms: subFormsData,
            listOfFormSubForm: formWithSubForms
          });
        }
      } finally {
        updateState({ isLoading: false });
      }
    },
    [handleGetDashboardForms]
  );

  const loadMetadata = useCallback(async id => {
    updateState({ isLoading: true, metadataId: id });

    const response = await handleGetMetadata(id);
    const result = handleResponse(response);
    if (result.isSuccess) {
      const { data } = result;

      // await loadForms(data.dataDomainId, data.year);

      updateState({
        dataDomainId: data.dataDomainId || '',
        year: data.year || '',
        formId: data.formId || '',
        subFormId: data.subformId || '',
        category1: data.category1Id || '',
        category2: data.category2Id || '',
        category3: data.category3Id || '',
        segmentType: data.dataSegmentType || '',
        dataPointType: data.dataPointType || '',
        dataPointScore: data.dataPoints || '',
        dataEvalMinScore: data.minPointsReq || '',
        qualtricsId: data.qualtricsId || '',
        sourceMapping: data.sourceMapping || '',
        targetMapping: data.targetMapping || '',
        updatedDttm: data.updatedDttm || '',
        updatedBy: data.updatedBy || '',
        createdDttm: data.createdDttm || '',
        createdBy: data.createdBy || '',
        formPointsPossible: data.formPointsPossible || ''
      });
    }

    updateState({ isLoading: false });
  }, []);

  const initializeMetadata = useCallback(async () => {
    updateState({ isLoading: true });
    try {
      const [domainsRes, yearsRes, segmentRes, dataPointRes] =
        await Promise.all([
          handleGetDomains(),
          handleGetYears(),
          handleGetSegmentTypes(),
          handleGetDataPointTypes()
        ]);

      updateState({
        listOfDataDomains: handleResponse(domainsRes).data || [],
        listOfYears: handleResponse(yearsRes).data || [],
        listOfSegmentTypes: handleResponse(segmentRes).data || [],
        listOfDataPointTypes: handleResponse(dataPointRes).data || []
      });

      const path = window.location.pathname;
      const id = path.substring(path.lastIndexOf('/') + 1);
      if (id && id > 0) {
        await loadMetadata(id);
      }
    } catch (error) {
      const errorMsg = handleCatch(error);
      updateState({
        showDownloadMessage: errorMsg || APP_LABELS.ERROR_MESSAGE,
        showDownloadStatus: true
      });
    } finally {
      updateState({ isLoading: false });
    }
  }, [
    handleGetDomains,
    handleGetYears,
    handleGetSegmentTypes,
    handleGetDataPointTypes,
    loadMetadata
  ]);

  const updateCategoryMappings = useCallback(
    data => {
      const nestedCategories = [];
      data.forEach(
        ({
          category1Id,
          category1Name,
          category2Id,
          category2Name,
          category3Id,
          category3Name
        }) => {
          // Find or create category1
          // eslint-disable-next-line no-shadow
          let category1 = nestedCategories.find(
            cat1 => cat1.category1Id === category1Id
          );
          if (!category1) {
            category1 = {
              category1Id,
              category1Name,
              category2: []
            };
            nestedCategories.push(category1);
          }

          // Find or create category2 under category1
          // eslint-disable-next-line no-shadow
          let category2 = category1.category2.find(
            cat2 => cat2?.category2Id === category2Id
          );
          if (!category2) {
            category2 = {
              category2Id,
              category2Name,
              category3: []
            };
            category1.category2.push(category2);
          }

          // Find or create category3 under category2
          // eslint-disable-next-line no-shadow
          let category3 = category2.category3.find(
            cat3 => cat3.category3Id === category3Id
          );
          if (!category3) {
            category3 = {
              category3Id,
              category3Name
            };
            category2.category3.push(category3);
          }
        }
      );
      const checkCombination = data.find(res => {
        const { category1Id, category2Id, category3Id } = res;
        if (category1 && !category2 && !category3) {
          return category1Id.toString() === category1.toString();
        }
        if (category1 && category2 && !category3) {
          return (
            category1Id.toString() === category1.toString() &&
            category2Id.toString() === category2.toString()
          );
        }
        if (category1 && category2 && category3) {
          return (
            category1Id.toString() === category1.toString() &&
            category2Id.toString() === category2.toString() &&
            category3Id.toString() === category3.toString()
          );
        }
        return false;
      });
      updateState({
        listOfCategories: nestedCategories,
        listOfCategory1: sortCategories(nestedCategories, 'category1Name'),
        category1: checkCombination ? category1 : '',
        category2: checkCombination ? category2 : '',
        category3: checkCombination ? category3 : ''
      });
    },
    [category1, category2, category3]
  );

  const calculateDatapointId = () => {
    // Start with the base data point ID
    if (!formId) return '';
    let dataPointID = `Q${formId}.${subFormId}`;

    // Check if all three categories are provided
    if (category1 && category2 && category3) {
      dataPointID = `${dataPointID}#${category1}_${category2}_${category3}`;
    }
    // Check if the first two categories are provided and the third one is not
    else if (category1 && category2 && !category3) {
      dataPointID = `${dataPointID}#${category1}_${category2}`;
    }
    // Check if only the first category is provided
    else if (category1 && !category2 && !category3) {
      dataPointID = `${dataPointID}#${category1}`;
    }

    return dataPointID;
  };

  const loadSubForms = useCallback(
    (_formId, _domainId, _year) => {
      const subForms = listOfFormSubForm.find(
        c =>
          c.form.formId.toString() === _formId.toString() &&
          c.form.year.toString() === _year.toString() &&
          c.form.domainId.toString() === _domainId.toString() &&
          c.form.subForms?.filter(
            e =>
              e.year.toString() === _year.toString() &&
              e.domainId.toString() === _domainId.toString()
          )
      )?.form?.subForms;

      updateState({ listOfSubForms: sortCategories(subForms, 'subFormName') });
    },
    [listOfFormSubForm]
  );

  const loadCategories = useCallback(
    async (_formId, _subFormId) => {
      // const localFormId = listOfFormSubForm.find(
      //   c => c.form.formId.toString() === _formId.toString()
      // )?.form?.formId;
      // const localSubFormId = listOfFormSubForm
      //   .find(c => c.form.formId.toString() === _formId.toString())
      //   ?.form?.subForms.find(
      //     c => c.formId.toString() === _subFormId.toString()
      //   )?.subFormId;
      const response = await handleGetCategoryMappings(
        dataDomainId,
        year,
        _formId,
        _subFormId
      );
      const result = handleResponse(response);
      updateCategoryMappings(result.data);
    },
    [listOfFormSubForm, category1, category2, category3]
  );

  const loadCategory2 = useCallback(
    _category1 => {
      const category2List = listOfCategory1.find(
        c => c.category1Id.toString() === _category1.toString()
      )?.category2;
      updateState({
        listOfCategory2: sortCategories(
          category2List?.filter(res => res?.category2Id),
          'category2Name'
        )
      });
    },
    [listOfCategories]
  );

  const loadCategory3 = useCallback(
    _category2 => {
      const category3List = listOfCategory2?.find(
        c => c.category2Id?.toString() === _category2?.toString()
      )?.category3;
      updateState({
        listOfCategory3: sortCategories(
          category3List?.filter(res => res?.category3Id),
          'category3Name'
        )
      });
    },
    [listOfCategory2]
  );

  const handleFormSubmit = async () => {
    if (!validateForm()) {
      return;
    }
    updateState({ isLoading: true });

    try {
      const localCategory1 = listOfCategories.find(
        c => c.category1Id?.toString() === category1?.toString()
      );
      const localCategory2 = localCategory1?.category2?.find(
        c => c.category2Id?.toString() === category2?.toString()
      );
      const localCategory3 = localCategory2?.category3?.find(
        c => c.category3Id?.toString() === category3?.toString()
      );

      const request = {
        year,
        dataDomainId,
        dataDomainName:
          listOfDataDomains.find(
            c => c.dataDomainId.toString() === dataDomainId.toString()
          )?.dataDomainName || '',
        dataPointDescription: '',
        dataPointType,
        dataPointGranularity: '',
        formName: listOfFormSubForm.find(
          c => c.form.formId.toString() === formId
        )?.form?.formName,
        formId,
        subformName: listOfFormSubForm
          .find(c => c.form.formId.toString() === formId)
          ?.form?.subForms.find(c => c.subFormId.toString() === subFormId)
          ?.subFormName,
        subformId: subFormId,
        category1Name: localCategory1?.category1Name,
        category1Id: category1,
        category2Name: localCategory2?.category2Name,
        category2Id: category2,
        category3Name: localCategory3?.category3Name,
        category3Id: category3,
        sourceMapping,
        targetMapping,
        dataEvalId: '',
        dataPoints: dataPointScore,
        qualtricsId,
        formPointsPossible,
        dataPointId: calculateDatapointId()
      };
      let response;

      if (metadataId && parseInt(metadataId, 10) > 0)
        response = await handleUpdateMetadata(
          request,
          parseInt(metadataId, 10)
        );
      else response = await handleAddMetadata(request);

      const result = handleResponse(response);
      if (result.isSuccess) {
        updateState({ showSuccess: true });
      } else {
        updateState({
          showSuccess: false,
          showFailure: true,
          errorMessage: result.message
        });
      }
    } catch (error) {
      const err = await handleCatch(error);
      updateState({ showSuccess: false, showFailure: true, errorMessage: err });
    } finally {
      updateState({ isLoading: false });
    }
  };

  useEffect(() => {
    if (category2 && listOfCategory2?.length > 0) loadCategory3(category2);
  }, [category2, listOfCategory2]);

  useEffect(() => {
    if (category1 && listOfCategory1?.length > 0) loadCategory2(category1);
  }, [category1, listOfCategory1]);

  useEffect(() => {
    if (formId && subFormId && listOfFormSubForm?.length > 0)
      loadCategories(formId, subFormId);
  }, [formId, subFormId, listOfFormSubForm.length]);

  useEffect(() => {
    if (formId && listOfFormSubForm?.length > 0)
      loadSubForms(formId, dataDomainId, year);
  }, [formId, year, dataDomainId, listOfFormSubForm.length]);

  useEffect(() => {
    if (year && dataDomainId) loadForms(dataDomainId, year);
  }, [year, dataDomainId]);

  useEffect(() => {
    initializeMetadata();
    window.scrollTo(0, 0);
  }, [initializeMetadata]);

  return (
    <BlockUi
      blocking={isLoading}
      message="Loading, please wait"
      loader={<Loader active type="semi-circle-spin" />}
    >
      <div className="ymca-wrapper manage-metadata-page">
        <main className="page-container ">
          <div className="container">
            <div className="d-flex justify-content-between">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb ymca-breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/metadata-manager/dashboard">
                      <span>Dashboard</span>
                    </Link>
                    <img
                      src={BreadcrumbIcon}
                      className="breadcrumb-icon"
                      alt=""
                    />
                  </li>
                  <li className="breadcrumb-item active">
                    {metadataId ? 'Edit' : 'Add'} Datapoint
                  </li>
                </ol>
              </nav>
              <Link
                className="ymca-title-link"
                to="/metadata-manager/dashboard"
              >
                <img src={BackIcon} className="me-1 mb-2" alt="back-icon" />
                Back
              </Link>
            </div>
            <h5 className="ymca-sub-heading mb-0">
              {metadataId ? 'Edit' : 'Add'} Datapoint
            </h5>
          </div>
          <hr />
          <div className="container border-wrapper">
            <div className="row ymca-form">
              <div className="col-12 col-md-6 col-lg-3">
                <div className="form-group">
                  <label htmlFor="ddlDoamins" className="form-label">
                    Data Domain <span className="text-mandatory ms-1">*</span>
                  </label>
                  <select
                    tabIndex={0}
                    id="ddlDoamins"
                    className="form-select"
                    aria-label="Default select example"
                    onChange={e =>
                      handleInputChange('dataDomainId', e.target.value)
                    }
                    value={dataDomainId}
                  >
                    <option selected>Select</option>
                    {listOfDataDomains.map(item => (
                      <option value={item.dataDomainId}>
                        {item.dataDomainName}
                      </option>
                    ))}
                  </select>
                  {!dataDomainId && state.errors.dataDomainId && (
                    <div className="errorNote">{state.errors.dataDomainId}</div>
                  )}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <div className="form-group">
                  <label htmlFor="ddlYears" className="form-label">
                    Year<span className="text-mandatory ms-1">*</span>
                  </label>
                  <select
                    tabIndex={0}
                    id="ddlYears"
                    className="form-select"
                    aria-label="Default select example"
                    onChange={e => handleInputChange('year', e.target.value)}
                    value={year}
                  >
                    <option value="">Select</option>
                    {listOfYears.map(item => (
                      <option value={item}>{item}</option>
                    ))}
                  </select>
                  {!year && state.errors.year && (
                    <div className="errorNote">{state.errors.year}</div>
                  )}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <div className="form-group">
                  <label htmlFor="ddlForms" className="form-label">
                    Form Name<span className="text-mandatory ms-1">*</span>
                  </label>
                  <select
                    tabIndex={0}
                    id="ddlForms"
                    className="form-select"
                    aria-label="Default select example"
                    onChange={e => handleInputChange('formId', e.target.value)}
                    value={formId}
                  >
                    <option selected>Select</option>
                    {listOfForms.map(item => (
                      <option value={item.formId} formId={item.formId}>
                        {item.formName}
                      </option>
                    ))}
                  </select>
                  {!formId && state.errors.formId && (
                    <div className="errorNote">{state.errors.formId}</div>
                  )}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <div className="form-group">
                  <label htmlFor="ddlSubForms" className="form-label">
                    Sub Form Name<span className="text-mandatory ms-1">*</span>
                  </label>
                  <select
                    id="ddlSubForms"
                    className="form-select"
                    aria-label="Default select example"
                    onChange={e =>
                      handleInputChange('subFormId', e.target.value)
                    }
                    value={subFormId}
                  >
                    <option selected>Select</option>
                    {listOfSubForms &&
                      listOfSubForms?.map(item => (
                        <option
                          value={item.subFormId}
                          formId={item.subFormId}
                          subFormId={item.subFormId}
                        >
                          {item.subFormName}
                        </option>
                      ))}
                  </select>
                  {!subFormId && state.errors.subFormId && (
                    <div className="errorNote">{state.errors.subFormId}</div>
                  )}
                </div>
              </div>

              <div className="col-12 col-md-6 col-lg-3">
                <div className="form-group">
                  <label htmlFor="ddlCategroy1" className="form-label">
                    Category 1<span className="text-mandatory ms-1">*</span>
                  </label>
                  <select
                    id="ddlCategroy1"
                    className="form-select"
                    aria-label="Default select example"
                    onChange={e =>
                      handleInputChange('category1', e.target.value)
                    }
                    value={category1}
                  >
                    <option value="">Select</option>
                    {listOfCategory1
                      ?.sort((a, b) =>
                        a.category1Name
                          .toUpperCase()
                          .localeCompare(b.category1Name.toUpperCase(), 'de', {
                            sensitivity: 'base'
                          })
                      )
                      .map(item => (
                        <option
                          title={item.category1Name}
                          value={item.category1Id}
                        >
                          {item.category1Name}
                        </option>
                      ))}
                  </select>
                  {!category1 && state.errors.category1 && (
                    <div className="errorNote">{state.errors.category1}</div>
                  )}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <div className="form-group">
                  <label htmlFor="ddlCategroy2" className="form-label">
                    Category 2
                  </label>
                  <select
                    id="ddlCategroy2"
                    className="form-select"
                    aria-label="Default select example"
                    onChange={e =>
                      handleInputChange('category2', e.target.value)
                    }
                    value={category2}
                  >
                    <option value="">Select</option>
                    {listOfCategory2 &&
                      listOfCategory2
                        .sort((a, b) =>
                          a.category2Name
                            .toUpperCase()
                            .localeCompare(
                              b.category2Name.toUpperCase(),
                              'de',
                              {
                                sensitivity: 'base'
                              }
                            )
                        )
                        .map(item => (
                          <option
                            title={item.category2Name}
                            value={item.category2Id}
                          >
                            {item.category2Name}
                          </option>
                        ))}
                  </select>
                  {!category2 && state.errors.category2 && (
                    <div className="errorNote">{state.errors.category2}</div>
                  )}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <div className="form-group">
                  <label htmlFor="ddlCategroy3" className="form-label">
                    Category 3
                  </label>
                  <select
                    id="ddlCategroy3"
                    className="form-select"
                    aria-label="Default select example"
                    onChange={e =>
                      handleInputChange('category3', e.target.value)
                    }
                    value={category3}
                  >
                    <option value="">Select</option>
                    {listOfCategory3 &&
                      listOfCategory3
                        ?.sort((a, b) =>
                          a.category3Name
                            .toUpperCase()
                            .localeCompare(
                              b.category3Name.toUpperCase(),
                              'de',
                              {
                                sensitivity: 'base'
                              }
                            )
                        )
                        .map(item => (
                          <option
                            title={item.category3Name}
                            value={item.category3Id}
                          >
                            {item.category3Name}
                          </option>
                        ))}
                  </select>
                  {!category3 && state.errors.category3 && (
                    <div className="errorNote">{state.errors.category3}</div>
                  )}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3" />

              <div className="col-12 col-md-6 col-lg-3">
                <div className="form-group">
                  <label htmlFor="ddlDataEvalGroupType" className="form-label">
                    Datapoint ID
                    <span className="text-mandatory ms-1">*</span>
                  </label>
                  <input
                    type="text"
                    maxLength={9}
                    className="form-control"
                    id="txtDataPointScore"
                    readOnly
                    value={calculateDatapointId()}
                  />
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <div className="form-group">
                  <label htmlFor="ddlDataPointType" className="form-label">
                    Data Point Type
                    <span className="text-mandatory ms-1">*</span>
                  </label>
                  <select
                    id="ddlDataPointType"
                    className="form-select"
                    maxLength={9}
                    aria-label="Default select example"
                    onChange={e =>
                      handleInputChange('dataPointType', e.target.value)
                    }
                    value={dataPointType}
                  >
                    <option selected>Select</option>
                    {listOfDataPointTypes.map(item => (
                      <option value={item}>{item}</option>
                    ))}
                  </select>
                  {!dataPointType && state.errors.dataPointType && (
                    <div className="errorNote">
                      {state.errors.dataPointType}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <div className="form-group">
                  <label htmlFor="txtDataPointScore" className="form-label">
                    Data Points
                    <span className="text-mandatory ms-1">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    maxLength={9}
                    id="txtDataPointScore"
                    name="txtDataPointScore"
                    placeholder="Enter"
                    onChange={e =>
                      handleInputChange('dataPointScore', e.target.value)
                    }
                    value={dataPointScore}
                  />
                  {state.errors.dataPointScore && (
                    <div className="errorNote">
                      {state.errors.dataPointScore}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <div className="form-group">
                  <label htmlFor="txtQualtricsId" className="form-label">
                    QualtricsID (optional)
                  </label>
                  <input
                    type="text"
                    maxLength={9}
                    className="form-control"
                    id="txtQualtricsId"
                    name="txtQualtricsId"
                    placeholder="Enter"
                    onChange={e =>
                      handleInputChange('qualtricsId', e.target.value)
                    }
                    value={qualtricsId}
                  />
                  {state.errors.qualtricsId && (
                    <div className="errorNote">{state.errors.qualtricsId}</div>
                  )}
                </div>
              </div>

              <div className="col-12 col-md-6 col-lg-3">
                <div className="form-group">
                  <label htmlFor="txtQualtricsId" className="form-label">
                    Form Points Possible
                    <span className="text-mandatory ms-1">*</span>
                  </label>
                  <input
                    type="text"
                    maxLength={9}
                    className="form-control"
                    id="txtQualtricsId"
                    name="txtQualtricsId"
                    placeholder="Enter"
                    onChange={e =>
                      handleInputChange('formPointsPossible', e.target.value)
                    }
                    value={formPointsPossible}
                  />
                  {!/^\d+$/.test(state.formPointsPossible) &&
                    state.errors.formPointsPossible && (
                      <div className="errorNote">
                        {state.errors.formPointsPossible}
                      </div>
                    )}
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6 col-lg-6">
                  <div className="form-group">
                    <label
                      htmlFor="txtDataSourceMapping"
                      className="form-label"
                    >
                      Data Source Mapping
                      <span className="text-mandatory ms-1">*</span>
                    </label>
                    <textarea
                      className="form-control"
                      id="txtDataSourceMapping"
                      name="txtDataSourceMapping"
                      placeholder="Enter"
                      maxLength={1000}
                      onChange={e =>
                        handleInputChange('sourceMapping', e.target.value)
                      }
                      value={sourceMapping}
                    />
                    {!sourceMapping && state.errors.sourceMapping && (
                      <div className="errorNote">
                        {state.errors.sourceMapping}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-6">
                  <div className="form-group">
                    <label
                      htmlFor="txtDataTargetMapping"
                      className="form-label"
                    >
                      Data Target Mapping
                      <span className="text-mandatory ms-1">*</span>
                    </label>
                    <textarea
                      className="form-control"
                      id="txtDataTargetMapping"
                      maxLength={1000}
                      name="txtDataTargetMapping"
                      placeholder="Enter"
                      onChange={e =>
                        handleInputChange('targetMapping', e.target.value)
                      }
                      value={targetMapping}
                    />
                    {!targetMapping && state.errors.targetMapping && (
                      <div className="errorNote">
                        {state.errors.targetMapping}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {metadataId && (
              <div className="updated-by-section">
                <div>
                  Last Updated By:
                  <b className="ms-1">{state.updatedBy || state.createdBy}</b>
                </div>
                <div className="ms-3">
                  On:
                  <b className="ms-1">
                    {state.updatedDttm
                      ? moment
                          .utc(state.updatedDttm)
                          .local()
                          .format('DD MMM YYYY, hh:mm a')
                      : moment
                          .utc(state.createdDttm)
                          .local()
                          .format('DD MMM YYYY, hh:mm a')}
                  </b>
                </div>
              </div>
            )}

            <div className="d-flex justify-content-end mt-4">
              <Link
                className="btn ymca-primary-outline-btn"
                type="button"
                to="/metadata-manager/dashboard"
              >
                Cancel
              </Link>
              <button
                className="btn ymca-primary-btn ms-3"
                type="button"
                onClick={() => handleFormSubmit()}
              >
                Submit
              </button>
            </div>
          </div>
        </main>
      </div>

      {canRedirect && <Redirect to="/metadata-manager/dashboard" />}
      <CustomModal
        show={showSuccess}
        type={APP_LABELS.SUCCESS}
        message={META_DATA.ADD_META_DATA_SUCCESS}
        submitButtonName="Ok"
        onSubmit={() => updateState({ showSuccess: false, canRedirect: true })}
      />

      <CustomModal
        show={showFailure}
        type={APP_LABELS.ERROR}
        message={errorMessage || APP_LABELS.ERROR_MESSAGE}
        submitButtonName="Ok"
        onSubmit={() => updateState({ showFailure: false })}
      />
    </BlockUi>
  );
};

MetaDataManagerManageMetadata.propTypes = {
  handleGetDomains: PropTypes.func.isRequired,
  handleGetYears: PropTypes.func.isRequired,
  handleGetDashboardForms: PropTypes.func.isRequired,
  handleGetCategoryMappings: PropTypes.func.isRequired,
  handleGetSegmentTypes: PropTypes.func.isRequired,
  handleGetDataPointTypes: PropTypes.func.isRequired,
  handleGetMetadata: PropTypes.func.isRequired,
  handleAddMetadata: PropTypes.func.isRequired,
  handleUpdateMetadata: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  handleGetDomains: getDomains,
  handleGetYears: getYears,
  handleGetDashboardForms: getDashboardForms,
  handleGetSegmentTypes: getSegmentTypes,
  handleGetDataPointTypes: getDataPointTypes,
  handleGetMetadata: getMetadata,
  handleAddMetadata: addMetadata,
  handleUpdateMetadata: updateMetadata,
  handleGetCategoryMappings: getCategoryMappingBySubForms
};

export default connect(null, mapDispatchToProps)(MetaDataManagerManageMetadata);
