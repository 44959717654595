import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import BlockUi from 'react-block-ui';
import { connect } from 'react-redux';
import { Loader } from 'react-loaders';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import Modal from '@modules/shared/components/reusable-components/model-popup';
import { addMetadataScoring } from '@redux/metadata-manager/data-scoring/actions';
import TextError from '@modules/shared/components/reusable-components/text-error';

import { APP_LABELS } from '@config/app-config';
import CustomModal from '@utilities/CustomModal';
import { handleResponse } from '../shared/index';

const ScoringViewDetails = ({
  row,
  handleAddMetadataScoring,
  getEvalDataBySubform,
  handleEvalDataByIds,
  scoreValues,
  listOfSegmentTypes
}) => {
  const [initialValues, setInitialValues] = useState({
    year: '',
    dataDomainId: '',
    formName: '',
    subformName: '',
    category1Name: '',
    category2Name: '',
    category3Name: '',
    evalGroup: '',
    evalGroupName: '',
    evalGroupType: '',
    minPointsReq: '',
    earnedScore: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showFailure, setShowFailure] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  useEffect(() => {
    setInitialValues({
      dataDomainId: row?.dataDomainId || '',
      year: row.year || '',
      formName: row.formName || '',
      subformName: row.subformName || '',
      category1Name: row.category1Name || '',
      category2Name: row.category2Name || '',
      category3Name: row.category3Name || '',
      evalGroupName: row.evalGroupName || '',
      evalGroup: row.evalGroup || '',
      evalGroupType: row.evalGroupType || '',
      minPointsReq: row.minPointsReq || '',
      earnedScore: row.earnedScore || ''
    });
  }, [row]);

  const validationSchema = Yup.object({
    minPointsReq: Yup.number()
      .typeError('Min Points Required must be a valid number')
      .nullable(),
    earnedScore: Yup.string().nullable().required('* Earned Score is required.')
  });

  const onSubmit = async (values, { setFieldValue, resetForm }) => {
    setIsLoading(true);

    const evalGroupTypeId = listOfSegmentTypes?.find(
      groupObj => groupObj?.name?.toString() === row?.evalGroupType?.toString()
    )?.evalTypeId;
    const payloadBody = {
      // formId: row.formId || null,
      // subformId: row.subformId || null,
      // category1Id: row.category1Id || null,
      // category2Id: row.category2Id || null,
      // evalGroupName: row.evalGroupName || null,
      // evalGroup: row.evalGroup || null,
      // evalGroupType: row.evalGroupType || null,
      // minPointsReq: values?.minPointsReq || null,
      // earnedScore: values?.earnedScore || null
      dataDomainId: row?.domainId,
      year: row?.year,
      evalGroupId: row?.evalGroupId,
      evalGroupName: row?.evalGroupName,
      evalGroupLevel: row?.evalGroup,
      evalTypeId: evalGroupTypeId,
      evalTypeName: row?.evalGroupType,
      minPointsReq: values?.minPointsReq || null,
      earnedScore: values?.earnedScore,
      categoryMappingId: 0
    };

    if (!payloadBody.minPointsReq) delete payloadBody.minPointsReq;

    try {
      const response = await handleAddMetadataScoring(payloadBody);
      const result = handleResponse(response);
      if (result?.isSuccess) {
        setIsLoading(false);
        // getEvalDataBySubform(scoreValues, scoreValues?.subformId);
        handleEvalDataByIds(scoreValues, null, row?.evalGroup);
        document.getElementById('btn-close').click();
        resetForm();
      } else {
        setErrorMessage(result.message);
        setShowFailure(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e, setFieldValue, name) => {
    const result = e.target.value.replace(/\D+/g, '');
    setFieldValue(name, result);
  };

  const renderFields = () => {
    return (
      <BlockUi
        blocking={isLoading}
        message="Loading, please wait"
        loader={<Loader active type="semi-circle-spin" />}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ values, setFieldValue }) => {
            return (
              <Form>
                {/* <div className="row">
                  <div className="col-12 col-md-6 col-lg-3 p-0">
                    <div className="ymca-bold-heading txt-mandatory">
                      Data Domain
                    </div>
                    <div className="ymca-modal-content notranslate">
                      {row?.dataDomainName || '-'}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3 p-0">
                    <div className="ymca-bold-heading txt-mandatory">Year</div>
                    <div className="ymca-modal-content notranslate">
                      {row?.year || '-'}
                    </div>
                  </div>

                  <div className="col-12 col-md-6 col-lg-3 p-0">
                    <div className="ymca-bold-heading txt-mandatory">
                      Form Name
                    </div>
                    <div className="ymca-modal-content notranslate">
                      {scoreValues?.formName || '-'}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-3 p-0">
                    <div className="ymca-bold-heading txt-mandatory">
                      Sub Form Name
                    </div>
                    <div className="ymca-modal-content notranslate">
                      {scoreValues?.subformName || '-'}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-12 col-lg-12 p-0">
                  <hr />
                </div>
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-4 p-0">
                    <div className="ymca-bold-heading txt-mandatory">
                      Category1 Name
                    </div>
                    <div className="ymca-modal-content notranslate">
                      {row?.category1Name || '-'}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 p-0">
                    <div className="ymca-bold-heading ">Category2 Name</div>
                    <div className="ymca-modal-content notranslate">
                      {row?.category2Name || '-'}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 p-0">
                    <div className="ymca-bold-heading ">Category3 Name</div>
                    <div className="ymca-modal-content notranslate">
                      {row?.category3Name || '-'}
                    </div>
                  </div>
                </div> 
                 <div className="col-12 col-md-12 col-lg-12 p-0">
                  <hr />
                </div> */}
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-4 p-0">
                    <div className="ymca-bold-heading txt-mandatory">
                      Scoring Group Level
                    </div>
                    <div className="ymca-modal-content notranslate">
                      {row?.evalGroup || '-'}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 p-0">
                    <div className="ymca-bold-heading txt-mandatory">
                      Scoring Group Name
                    </div>
                    <div className="ymca-modal-content notranslate">
                      {row?.evalGroupName || '-'}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 p-0">
                    <div className="ymca-bold-heading txt-mandatory">
                      Scoring Group Type
                    </div>
                    <div className="ymca-modal-content notranslate">
                      {row?.evalGroupType || '-'}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 p-0">
                    <div className="ymca-bold-heading txt-mandatory">
                      Scoring Subgroup Name
                    </div>
                    <div className="ymca-modal-content notranslate">
                      {row?.evalGroupName || '-'}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 p-0">
                    <div className="ymca-bold-heading txt-mandatory">
                      Points Possible
                    </div>
                    <div className="ymca-modal-content notranslate">
                      {row?.pointsPossible || '-'}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 p-0" />

                  <div className="col-12 col-md-6 col-lg-4 p-0 form-group">
                    <div className="ymca-bold-heading ">
                      Min Points Required
                    </div>
                    <div className="ymca-modal-content notranslate  col-6">
                      <Field
                        type="text"
                        name="minPointsReq"
                        className="me-2 form-control"
                        maxLength={9}
                        onChange={e =>
                          handleChange(e, setFieldValue, 'minPointsReq')
                        }
                      />
                      <ErrorMessage component={TextError} name="minPointsReq" />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 p-0 form-group">
                    <div className="ymca-bold-heading txt-mandatory">
                      Earned Score
                    </div>
                    <div className="ymca-modal-content notranslate col-6">
                      <Field
                        type="text"
                        name="earnedScore"
                        className="me-2 form-control"
                        maxLength={9}
                        onChange={e =>
                          handleChange(e, setFieldValue, 'earnedScore')
                        }
                      />
                      <ErrorMessage component={TextError} name="earnedScore" />
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn ymca-savingBTN ymca-btnRightTitle float-end"
                >
                  Submit
                </button>
              </Form>
            );
          }}
        </Formik>

        <CustomModal
          show={showFailure}
          type={APP_LABELS.ERROR}
          message={errorMessage || APP_LABELS.ERROR_MESSAGE}
          submitButtonName="Ok"
          onSubmit={() => setShowFailure(false)}
        />
      </BlockUi>
    );
  };

  return (
    <Modal
      popupTitle="Edit Details"
      showClose="true"
      classNames="metadata-modal"
    >
      {renderFields()}
    </Modal>
  );
};

ScoringViewDetails.propTypes = {
  row: PropTypes.node.isRequired,
  activeKpi: PropTypes.string.isRequired,
  editSubFormScoringMetadata: PropTypes.func.isRequired,
  handleAddMetadataScoring: PropTypes.func.isRequired
};
const mapDispatchToProps = {
  handleAddMetadataScoring: addMetadataScoring
};
export default connect(
  null,
  mapDispatchToProps
)(React.memo(ScoringViewDetails));
