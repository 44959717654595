import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import EditPencilIcon from '@static/images/swimRegistration/edit-pencil-icon.svg';
import DeleteIcon from '@static/images/swimRegistration/delete-icon.svg';
import { APP_LABELS } from '@config/app-config';

export const METADATA_CATEGORIES_COLUMNS = ({
  handleDelete,
  domain,
  handleEdit,
  role,
  selectedDataDomain,
  selectedYear,
  dataDomainId
}) => [
  {
    Header: 'Data Domain',
    accessor: 'dataDomainName',
    className: 'notranslate',
    Cell: () => {
      return <span>{domain}</span>;
    }
  },
  {
    Header: 'Form',
    accessor: 'formName',
    className: 'notranslate'
  },
  {
    Header: 'Sub-Form',
    accessor: 'subFormName',
    className: 'notranslate'
  },
  {
    Header: 'Category 1',
    accessor: 'category1Name',
    className: 'notranslate ymca-word-break ymca-ellipse'
  },
  {
    Header: 'Category 2',
    accessor: 'category2Name',
    className: 'notranslate ymca-word-break ymca-ellipse'
  },
  {
    Header: 'Category 3',
    accessor: 'category3Name',
    className: 'notranslate ymca-word-break ymca-ellipse'
  },

  {
    Header: 'Last Updated Name & Time',
    accessor: row => {
      if (row.lastUpdatedNameTime)
        return (
          <>
            {moment
              .utc(row.lastUpdatedNameTime)
              .local()
              .format('DD MMM YYYY, hh:mm a')}
          </>
        );
    },
    // accessor: "lastUpdatedNameTime",
    className: 'notranslate ymca-word-break ymca-ellipse'
  },
  {
    Header: 'Actions',
    accessor: 'categoryMappingId',

    Cell: v => (
      <div className="d-flex">
        {role !== APP_LABELS.YUSA_DATA_VIEWER ? (
          <>
            <Link
              className="btn table-link"
              type="button"
              onClick={() => {
                sessionStorage.setItem('categorydomainId', dataDomainId);
                sessionStorage.setItem('categorydomain', selectedDataDomain);
                sessionStorage.setItem('categoryyear', selectedYear);
              }}
              to={`/metadata-manager/categories/mapping`}
            >
              <img
                src={EditPencilIcon}
                className="me-2 mb-1"
                alt="edit-pencil-icon"
                onClick={() => handleEdit(v.value)}
              />
            </Link>
            <button
              className="btn table-link"
              type="button"
              onClick={() => {
                handleDelete(v.value);
                sessionStorage.setItem('categorydomain', selectedDataDomain);
                sessionStorage.setItem('categoryyear', selectedYear);
              }}
            >
              <img
                src={DeleteIcon}
                className="me-2 mb-1"
                alt="edit-pencil-icon"
              />
            </button>
          </>
        ) : (
          <>-</>
        )}
      </div>
    )
  }
];

export const METADATA_CONFIGURE_CATEGORIES_COLUMNS = () => [
  {
    Header: 'Category',
    accessor: 'category',
    className: 'notranslate ymca-word-break ymca-ellipse'
  },
  {
    Header: 'No.of Items',
    accessor: 'itemCount',
    className: 'notranslate ymca-word-break ymca-ellipse'
  },
  {
    Header: 'Last Updated Date & Time',
    accessor: row =>
      `${moment(row.lastUpdatedDate).format('DD MMM YYYY, hh:mm a')}`,
    className: 'notranslate ymca-word-break ymca-ellipse '
  },
  {
    Header: 'Actions',
    accessor: 'sno',
    className: 'notranslate ymca-word-break ymca-ellipse ',
    Cell: v => (
      <>
        <div className="d-flex">
          <Link
            to={`/metadata-manager/categories/manage-configure-items`}
            className="table-link "
            onClick={() =>
              sessionStorage.setItem('metaData_categoryItem', v.value)
            }
          >
            View Details
          </Link>
        </div>
      </>
    )
  }
];

export const METADATA_CATEGORIES_ITEMS_COLUMNS = ({
  handleDelete,
  handleUpdate,
  role
}) => [
  {
    Header: 'Items',
    accessor: 'name',
    className: 'notranslate'
  },
  {
    Header: 'Actions',
    accessor: 'id',
    className: 'notranslate ymca-word-break ymca-ellipse ',
    Cell: v => (
      <div className="d-flex">
        {role !== APP_LABELS.YUSA_DATA_VIEWER ? (
          <>
            {/* <button
              className="btn table-link"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#addModal"
              onClick={() => {
                handleUpdate(v.value, v?.cell?.row?.values?.name);
              }}
            >
              <img
                src={EditPencilIcon}
                className="me-2 mb-1"
                alt="edit-pencil-icon"
              />{' '}
            </button> */}
            <button
              className="btn table-link"
              type="button"
              onClick={() => handleDelete(v.value)}
            >
              <img
                src={DeleteIcon}
                className="me-2 mb-1"
                alt="edit-pencil-icon"
              />
            </button>
          </>
        ) : (
          <>-</>
        )}
      </div>
    )
  }
];
