import React from 'react';
import { Link } from 'react-router-dom';
const EVALUATION_GROUPS_COLUMN = () => [
  // {
  //   Header: 'Association No.',
  //   accessor: 'Association',
  //   className: 'notranslate'
  // },
  {
    Header: 'Scoring Group ID',
    accessor: 'ScoringGroupID',
    className: 'notranslate '
  },
  {
    Header: 'Scoring Group Name',
    accessor: 'ScoringGroupName',
    className: 'text-left notranslate'
  },

  {
    Header: 'Eval Group Name',
    accessor: 'EvaluationGroupName',
    className: 'text-left notranslate'
  },
  {
    Header: 'Eval Group ID',
    accessor: 'EvaluationGroupID',
    className: 'notranslate'
  },
  {
    Header: 'Points Possible',
    accessor: 'PointsPossible',
    className: 'notranslate'
  },
  {
    Header: 'Points Awarded',
    accessor: 'PointsEarned',
    className: 'notranslate'
  },
  {
    Header: 'Min.Points Required',
    accessor: 'MinPointsRequired',
    className: 'notranslate'
  },
  {
    Header: 'Earned Score',
    accessor: 'EarnedScore',
    className: 'notranslate'
  },
  {
    Header: 'Actions',
    accessor: 'ShowDataPoints',
    Cell: v => (
      <>
        <div className="d-flex">
          <Link
            to={v.value}
            className="table-link"
            onClick={() =>
              sessionStorage.setItem('metaData_categoryItem', v.value)
            }
          >
            Show Datapoints
          </Link>
        </div>
      </>
    )
  }
];

export default EVALUATION_GROUPS_COLUMN;
