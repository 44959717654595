import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import Select from 'react-select';
import moment from 'moment';
import { Link, Redirect } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { APP_LABELS, CDP, REGEX } from '@config/app-config';
import TextError from '@modules/shared/components/reusable-components/text-error';
import BlockUi from 'react-block-ui';
import { connect } from 'react-redux';
import { Loader } from 'react-loaders';
import get from 'lodash.get';
import {
  getSwimSafetyProgramsDetails,
  getSwimSafetyProgramsAddORupdate
} from '@redux/siwm-safety/swim-programs/actions';
import PropTypes from 'prop-types';
import BackIcon from '@static/images/back-icon.svg';
import { getSwimAssociationDropdown } from '@redux/siwm-safety/dashboard/actions';
import ErrorPopup from '@modules/employee-data-collection/components/upload-file/error-popup';
import CustomModal from '@utilities/CustomModal';
import { getSwimSafetyBranchList } from '@redux/siwm-safety/swim-branches/actions';
import commonAxios from '@utilities/CommonAxios';
import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';
import GrantList from './grant-list-strapi';

const { ALPHABETSWITHHYPHEN, NUMERIC, ALPHANUMERICWITHSPACESANDHYPHEN } = REGEX;

const AddProgram = props => {
  const {
    getSwimProgram,
    saveSwimProgram,
    handlegetAssociationList,
    handleSwimSafetyBranchList,
    LoginUserData
  } = props;
  const role = get(LoginUserData, 'user.role[0]');
  const [loader, setLoader] = useState(false);
  const [associationList, setAssociationList] = useState([]);
  const [branchDropdown, setBranchDropdown] = useState([]);
  const [grantDropdown, setGrantDropdown] = useState([]);
  const [programData, setProgramData] = useState({});
  const [redirect, setRedirect] = useState(false);
  const [successPopup, setSuccessPopup] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorPopup, setErrorPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const associateData = JSON.parse(
    sessionStorage.getItem('yusa_selected_associate')
  );

  const associationNumber = get(associateData, 'associationNumber', '')
    ? get(associateData, 'associationNumber', '')
    : get(LoginUserData, 'user.associations[0]', '');

  const swimProgramData = JSON.parse(
    sessionStorage.getItem('selected_swimprogram')
  );
  const programId = get(swimProgramData, 'id', '');

  const handleRemoveStartWhiteSpaces = (e, setFieldValue, fieldName) => {
    const value = e.target.value.trimStart();
    setFieldValue(fieldName, value);
  };

  const validationObj = {
    association: Yup.string().nullable().required('* Association is required.'),
    branch: Yup.string().nullable().required('* Branch is required.'),
    programName: Yup.string()
      .nullable()
      .matches(
        ALPHANUMERICWITHSPACESANDHYPHEN,
        '* Program Name should only have Alphabets, Numbers, Hyphens and Spaces.'
      )
      .max(99, '* Program Name length should be less than 100 Charecters.')
      .required('* Program Name is required.'),
    startDate: Yup.string().nullable().required('* Start date is required.'),
    endDate: Yup.string().nullable().required('* End date is required.'),
    instructorName: Yup.string()
      .nullable()
      .matches(
        ALPHABETSWITHHYPHEN,
        '* Instructor Name should only have Alphabets, Hyphens and Spaces.'
      )
      .max(99, '* Instructor Name length should be less than 100 Charecters.')
      .required('* Instructor name is required.'),
    noOfLessons: Yup.string()
      .nullable()
      .matches(NUMERIC, '* NO. of Lessons should only be Numbers.')
      .max(3, '* Number  of Lessons should be less than 1000')
      .required('* Number  of Lessons is required.'),
    lengthOfLessons: Yup.string()
      .matches(NUMERIC, '* Length of each swim class should only be Numbers.')
      .nullable()
      .required('* Length of each swim class is required.'),
    partOfGrandFunded: Yup.string().required(
      '* Part of a grant funded Project is required.'
    ),
    grantId: Yup.object()
      .shape({ value: Yup.string(), label: Yup.string() })
      .nullable()
      .when('partOfGrandFunded', {
        is: 'true',
        then: Yup.object()
          .shape({ value: Yup.string(), label: Yup.string() })
          .nullable()
          .required('* Special Project / Grant is required.')
      }),
    sawLessonPlan: Yup.string().required(
      '* Followed SAW lesson plan is required.'
    )
  };
  const validationSchema = Yup.object(validationObj);

  const getSwimProgramDetails = () => {
    getSwimProgram(programId).then(result => {
      if (result?.data?.data) {
        setProgramData(result?.data);
      }
    });
  };

  const initialValues = {
    association: associationNumber,
    branch: '',
    programName: '',
    startDate: '',
    endDate: '',
    instructorName: '',
    noOfLessons: '',
    lengthOfLessons: '',
    sawLessonPlan: '',
    partOfGrandFunded: '',
    grantId: null
  };

  const setEditValues = () => {
    let editValues = {
      association: associationNumber,
      branch: '',
      programName: '',
      startDate: '',
      endDate: '',
      instructorName: '',
      noOfLessons: '',
      lengthOfLessons: '',
      sawLessonPlan: '',
      partOfGrandFunded: '',
      grantId: null
    };

    if (programData?.data) {
      editValues = {
        ...editValues,
        branch: programData?.data?.branchId.toString(),
        programName: programData?.data?.programName,
        startDate: programData?.data?.startDate.split('T')[0],
        endDate: programData?.data?.endDate.split('T')[0],
        instructorName: programData?.data?.instructorName,
        noOfLessons: programData?.data?.noOfLessons,
        lengthOfLessons: programData?.data?.lengthOfLessons,
        sawLessonPlan: programData?.data?.isFollowSAWLessonPlans
          ? 'true'
          : 'false',
        partOfGrandFunded: programData?.data?.specialGrant ? 'true' : 'false',
        grantId: programData?.data?.specialGrant
          ? {
              value: programData?.data?.specialGrant,
              label: programData?.data?.specialGrant
            }
          : null
      };
    }
    return editValues;
  };

  const onSubmit = values => {
    const payloadBody = {
      id: programId || 0,
      programName: values.programName,
      startDate: values.startDate,
      endDate: values.endDate,
      branchId: parseInt(values.branch, 10),
      instructorName: values.instructorName,
      noOfLessons: parseInt(values.noOfLessons, 10),
      lengthOfLessons: values.lengthOfLessons,
      isFollowSAWLessonPlans: values.sawLessonPlan === 'true',
      specialGrant:
        values.partOfGrandFunded === 'true' ? values.grantId.value : ''
    };
    setLoader(true);
    saveSwimProgram(payloadBody)
      .then(res => {
        if (res.status === 200) {
          setLoader(false);
          setSuccessPopup(true);
          setSuccessMessage(
            programId
              ? APP_LABELS.SWIMPROGRAM_UPDATED_MESSAGE
              : APP_LABELS.SWIMPROGRAM_SUCCESS_MESSAGE
          );
        } else {
          setLoader(false);
          setErrorMessage(res?.message);
          setErrorPopup(true);
        }
      })
      .catch(() => {
        setLoader(false);
      });
  };

  useEffect(() => {
    setLoader(true);
    if (programId) getSwimProgramDetails();
    commonAxios({
      url: '/grant-name-lists',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('strapitoken')}`
      }
    })
      .then(res => {
        const data = res?.data?.data?.map(item => ({
          ...item,
          label: item?.attributes?.GrantName,
          value: item?.attributes?.GrantName
        }));
        if (role !== APP_LABELS.YUSA_DATA_STEWARD) {
          setGrantDropdown(
            data.filter(
              grant => grant.attributes.Year >= new Date().getFullYear()
            )
          );
        } else {
          setGrantDropdown(data);
        }
      })
      .catch(() => {
        const data = GrantList?.data?.map(item => ({
          ...item,
          label: item?.attributes?.GrantName,
          value: item?.attributes?.GrantName
        }));
        if (role !== APP_LABELS.YUSA_DATA_STEWARD) {
          setGrantDropdown(
            data.filter(
              grant => grant.attributes.Year >= new Date().getFullYear()
            )
          );
        } else {
          setGrantDropdown(data);
        }
      });

    handlegetAssociationList().then(result => {
      if (result?.data?.data) {
        setAssociationList(result?.data?.data);
      }
    });

    handleSwimSafetyBranchList(get(associateData, 'associationNumber', ''))
      .then(result => {
        if (result?.data?.data) {
          setBranchDropdown(result?.data?.data?.branches);
        }
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });

    return () => {
      sessionStorage.removeItem('selected_swimprogram');
    };
  }, []);

  const validate = values => {
    const errors = {};
    if (values.lengthOfLessons && Number(values.lengthOfLessons) + 1 === 1) {
      errors.lengthOfLessons = 'Invalid Length Of Lessons';
    }
    return errors;
  };

  return (
    <BlockUi
      blocking={loader}
      message="Loading, please wait"
      loader={<Loader active type="semi-circle-spin" />}
    >
      <div className="ymca-wrapper">
        <main className="page-container">
          <div className="ymca-data-wrapper volunteer-dashboard-wrapper">
            <div className="container">
              <section className="dashboard-header">
                <div className="row align-items-center mt-3">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="ymca-associate-info-wrapper">
                      <h2 className="ymca-sub-heading mb-2">
                        {programId
                          ? APP_LABELS.EDIT_SWIM_PROGRAM
                          : APP_LABELS.ADD_SWIM_PROGRAM}
                      </h2>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 text-lg-end text-md-end">
                    <Link
                      className="ymca-title-link"
                      to="/swim/association/dashboard"
                    >
                      <img
                        src={BackIcon}
                        className="ymca-back-link"
                        alt="back-icon"
                      />
                      {APP_LABELS.DASH_BACK}
                    </Link>
                  </div>
                </div>
              </section>
              <hr className="custom-hr mt-0" />

              <div className="container">
                <Formik
                  initialValues={programId ? setEditValues() : initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                  enableReinitialize
                  validate={validate}
                >
                  {({ values, handleChange, setFieldValue }) => (
                    <Form
                      autoComplete="off"
                      className="ymca-registeration-form"
                    >
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="form-group mb-3">
                            <label htmlFor="association" className="form-label">
                              {APP_LABELS.ASSOCIATION_NAME}
                              <span className="text-mandatory ms-1">*</span>
                            </label>
                            <select
                              name="association"
                              className="multiSelectContainer searchWrapper select-arrow-hide form-select longSearchWrapper pe-5 notranslate"
                              aria-label="Default select example"
                              value={values.association}
                              disabled
                            >
                              <option value="">{CDP.SELECT_DEFAULT}</option>
                              {associationList?.map(data => (
                                <option
                                  key={data.associationNumber}
                                  value={data.associationNumber}
                                >
                                  {data.name}
                                </option>
                              ))}
                            </select>
                            <ErrorMessage
                              component={TextError}
                              name="association"
                            />
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-group mb-3">
                            <label htmlFor="branch" className="form-label">
                              {APP_LABELS.BRANCH}
                              <span className="text-mandatory ms-1">*</span>
                            </label>
                            <select
                              name="branch"
                              className="multiSelectContainer searchWrapper select-arrow-hide form-select longSearchWrapper pe-5 notranslate"
                              aria-label="Default select example"
                              value={values.branch}
                              onChange={handleChange}
                            >
                              <option value="">{CDP.SELECT_DEFAULT}</option>
                              {branchDropdown?.map(data => (
                                <option
                                  key={data.branchid}
                                  value={data.branchid}
                                >
                                  {data.branchname}
                                </option>
                              ))}
                            </select>
                            <ErrorMessage component={TextError} name="branch" />
                          </div>
                        </div>
                      </div>
                      <h2 className="ymca-section-heading">
                        {APP_LABELS.PROGRAM_DETAILS}
                      </h2>
                      <div className="col-lg-12">
                        <div className="row">
                          <div className="col-md-4">
                            <span className="mb-2 required">
                              {APP_LABELS.PROGRAM_NAME}
                              <span className="text-mandatory">&#42;</span>
                            </span>
                            <div className="form-group">
                              <Field
                                maxLength={100}
                                name="programName"
                                className="form-control"
                                type="text"
                                placeholder="Enter Program Name"
                                value={values.programName}
                                onChange={e => {
                                  handleRemoveStartWhiteSpaces(
                                    e,
                                    setFieldValue,
                                    'programName'
                                  );
                                }}
                              />
                              <ErrorMessage
                                component={TextError}
                                name="programName"
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <span className="mb-2 required">
                              {APP_LABELS.START_DATE}
                              <span className="text-mandatory">&#42;</span>
                            </span>
                            <div className="form-group">
                              <input
                                type="date"
                                className="form-control w-100"
                                placeholder=""
                                name="startDate"
                                onChange={e => {
                                  setFieldValue('endDate', '');
                                  handleChange(e, setFieldValue, 'startDate');
                                }}
                                value={values.startDate}
                                // onKeyDown={e => {
                                //   e.preventDefault();
                                // }}
                              />
                              <ErrorMessage
                                component={TextError}
                                name="startDate"
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <span className="mb-2 required">
                              {APP_LABELS.END_DATE}
                              <span className="text-mandatory">&#42;</span>
                            </span>
                            <div className="form-group">
                              <input
                                type="date"
                                className="form-control w-100"
                                placeholder=""
                                name="endDate"
                                onChange={handleChange}
                                value={values.endDate}
                                disabled={!values.startDate}
                                min={moment(new Date(values.startDate))
                                  .add(1, 'days')
                                  .format('YYYY-MM-DD')}
                                // onKeyDown={e => {
                                //   e.preventDefault();
                                // }}
                              />
                              <ErrorMessage
                                component={TextError}
                                name="endDate"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="row">
                          <div className="col-md-4">
                            <span className="mb-2 required">
                              {APP_LABELS.INSTRUCTOR_NAME}
                              <span className="text-mandatory">&#42;</span>
                            </span>
                            <div className="form-group">
                              <Field
                                maxLength={100}
                                name="instructorName"
                                className="form-control"
                                type="text"
                                placeholder="Enter Instructor Name"
                                value={values.instructorName}
                                onChange={e => {
                                  handleRemoveStartWhiteSpaces(
                                    e,
                                    setFieldValue,
                                    'instructorName'
                                  );
                                }}
                              />
                              <ErrorMessage
                                component={TextError}
                                name="instructorName"
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <span className="mb-2 required">
                              Number of lesson in the session{/* */}
                              <span className="text-mandatory">&#42;</span>
                            </span>
                            <div className="form-group">
                              <Field
                                maxLength={3}
                                name="noOfLessons"
                                className="form-control"
                                type="text"
                                placeholder="Enter Number of Lessons"
                              />
                              <ErrorMessage
                                component={TextError}
                                name="noOfLessons"
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <span className="mb-2 required">
                              Length of each swim class (minutes){/* */}
                              <span className="text-mandatory">&#42;</span>
                            </span>
                            <div className="form-group">
                              <Field
                                maxLength={3}
                                name="lengthOfLessons"
                                className="form-control"
                                type="text"
                                placeholder="Enter Length of each swim class"
                              />
                              <ErrorMessage
                                component={TextError}
                                name="lengthOfLessons"
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="ymca-covidRelif pt-0 ps-0 mb-3">
                              <label
                                htmlFor="Association"
                                className="form-label"
                              >
                                Followed SAW lesson plan{/* */}
                                <span className="text-mandatory ms-1">*</span>
                              </label>

                              <div className="ymca-radioBTN d-flex pb-0">
                                <div className="me-5">
                                  <Field
                                    type="radio"
                                    name="sawLessonPlan"
                                    className="me-2"
                                    value="true"
                                    checked={values.sawLessonPlan === 'true'}
                                  />
                                  <span>Yes</span>
                                </div>
                                <div className="">
                                  <Field
                                    type="radio"
                                    name="sawLessonPlan"
                                    className="me-2"
                                    value="false"
                                    checked={values.sawLessonPlan === 'false'}
                                  />
                                  <span>No</span>
                                </div>
                              </div>
                              <ErrorMessage
                                component={TextError}
                                name="sawLessonPlan"
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="ymca-covidRelif pt-0 ps-0 mb-3">
                              <label
                                htmlFor="Association"
                                className="form-label"
                              >
                                Is this program part of a special project or
                                grant?{/* */}
                                <span className="text-mandatory ms-1">*</span>
                              </label>
                              <div className="ymca-radioBTN d-flex pb-0">
                                <div className="me-5">
                                  <Field
                                    type="radio"
                                    name="partOfGrandFunded"
                                    className="me-2"
                                    value="true"
                                    checked={
                                      values.partOfGrandFunded === 'true'
                                    }
                                  />
                                  <span>Yes</span>
                                </div>
                                <div className="">
                                  <Field
                                    type="radio"
                                    name="partOfGrandFunded"
                                    className="me-2"
                                    value="false"
                                    checked={
                                      values.partOfGrandFunded === 'false'
                                    }
                                  />
                                  <span>No</span>
                                </div>
                              </div>
                              <ErrorMessage
                                component={TextError}
                                name="partOfGrandFunded"
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            {values.partOfGrandFunded === 'true' && (
                              <div className="form-group mb-3">
                                <label htmlFor="grantId" className="form-label">
                                  Special Project / Grant{/* */}
                                  <span className="text-mandatory ms-1">*</span>
                                </label>
                                <Select
                                  name="grantId"
                                  className="swim-grant-dropdown notranslate"
                                  value={values.grantId}
                                  onChange={value =>
                                    setFieldValue('grantId', value)
                                  }
                                  placeholder="Select"
                                  options={grantDropdown}
                                  isClearable
                                />

                                <ErrorMessage
                                  component={TextError}
                                  name="grantId"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div>
                        <button
                          className="btn ymca-primary-btn swim-addbranch-save-btn mt-3"
                          type="submit"
                        >
                          {programId ? APP_LABELS.UPDATE : APP_LABELS.SAVE}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </main>
      </div>

      {redirect && <Redirect to="/swim/association/dashboard" />}
      <ErrorPopup
        handleCloseErrorPopup={() => {
          setErrorPopup(false);
        }}
        errorMessage={errorMessage}
        showErrorPopup={errorPopup}
      />
      {successPopup && (
        <CustomModal
          show={successPopup}
          type={APP_LABELS.SUCCESS}
          message={successMessage}
          submitButtonName="Ok"
          onSubmit={() => {
            setRedirect(true);
          }}
        />
      )}
    </BlockUi>
  );
};

AddProgram.propTypes = {
  getSwimProgram: PropTypes.func.isRequired,
  saveSwimProgram: PropTypes.func.isRequired,
  handlegetAssociationList: PropTypes.func.isRequired,
  handleSwimSafetyBranchList: PropTypes.func.isRequired,
  LoginUserData: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapDispatchToProps = {
  getSwimProgram: getSwimSafetyProgramsDetails,
  saveSwimProgram: getSwimSafetyProgramsAddORupdate,
  handlegetAssociationList: getSwimAssociationDropdown,
  handleSwimSafetyBranchList: getSwimSafetyBranchList
};

const mapStateToProps = state => ({
  LoginUserData: AssociationAdminLoginDetailsSelector(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(AddProgram));
