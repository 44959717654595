const GrantList = {
  data: [
    {
      id: 1,
      attributes: {
        GrantName: 'SIF Swim Lessons Grants 2024',
        Year: '2024',
        createdAt: '2023-08-31T12:32:07.338Z',
        updatedAt: '2023-08-31T12:32:08.953Z',
        publishedAt: '2023-08-31T12:32:08.948Z'
      }
    },
    {
      id: 2,
      attributes: {
        GrantName: 'SIF Community Engagement Grants 2024',
        Year: '2024',
        createdAt: '2023-08-31T12:32:24.297Z',
        updatedAt: '2023-08-31T12:32:26.294Z',
        publishedAt: '2023-08-31T12:32:26.290Z'
      }
    },
    {
      id: 3,
      attributes: {
        GrantName: 'CDC Community Engagement Project 2023-2024',
        Year: '2024',
        createdAt: '2023-08-31T12:32:56.224Z',
        updatedAt: '2023-08-31T12:32:57.846Z',
        publishedAt: '2023-08-31T12:32:57.842Z'
      }
    },
    {
      id: 4,
      attributes: {
        GrantName: 'CDC Autism Swim Project 2023-2024',
        Year: '2024',
        createdAt: '2023-08-31T12:33:18.766Z',
        updatedAt: '2023-08-31T12:33:20.416Z',
        publishedAt: '2023-08-31T12:33:20.412Z'
      }
    },
    {
      id: 5,
      attributes: {
        GrantName: 'Florida Alliance Grants',
        Year: '2024',
        createdAt: '2023-08-31T12:33:38.835Z',
        updatedAt: '2023-08-31T12:33:40.319Z',
        publishedAt: '2023-08-31T12:33:40.315Z'
      }
    },
    {
      id: 6,
      attributes: {
        GrantName: 'SIF Teen/Adult Swim Lesson 2024 ',
        Year: '2024',
        createdAt: '2023-08-31T12:33:54.745Z',
        updatedAt: '2023-08-31T12:33:56.261Z',
        publishedAt: '2023-08-31T12:33:56.255Z'
      }
    },
    {
      id: 7,
      attributes: {
        GrantName: '2025 SIF Swim Lesson Grant',
        Year: '2025',
        createdAt: '2023-08-31T12:32:07.338Z',
        updatedAt: '2023-08-31T12:32:08.953Z',
        publishedAt: '2023-08-31T12:32:08.948Z'
      }
    },
    {
      id: 8,
      attributes: {
        GrantName: '2025 SIF Building Aquatic Trust Grant',
        Year: '2025',
        createdAt: '2023-08-31T12:32:24.297Z',
        updatedAt: '2023-08-31T12:32:26.294Z',
        publishedAt: '2023-08-31T12:32:26.290Z'
      }
    },
    {
      id: 9,
      attributes: {
        GrantName: '2025 CDC Autism Swim Skills Grant',
        Year: '2025',
        createdAt: '2023-08-31T12:32:56.224Z',
        updatedAt: '2023-08-31T12:32:57.846Z',
        publishedAt: '2023-08-31T12:32:57.842Z'
      }
    },
    {
      id: 10,
      attributes: {
        GrantName: '2025 CDC CBO Swim Skills Grant',
        Year: '2025',
        createdAt: '2023-08-31T12:33:18.766Z',
        updatedAt: '2023-08-31T12:33:20.416Z',
        publishedAt: '2023-08-31T12:33:20.412Z'
      }
    },
    {
      id: 11,
      attributes: {
        GrantName: '2025 Florida Alliance Grant',
        Year: '2025',
        createdAt: '2023-08-31T12:33:38.835Z',
        updatedAt: '2023-08-31T12:33:40.319Z',
        publishedAt: '2023-08-31T12:33:40.315Z'
      }
    }

    // {
    //   id: 6,
    //   attributes: {
    //     GrantName: 'SIF Teen/Adult Swim Lesson 2024 ',
    //     createdAt: '2023-08-31T12:33:54.745Z',
    //     updatedAt: '2023-08-31T12:33:56.261Z',
    //     publishedAt: '2023-08-31T12:33:56.255Z'
    //   }
    // }
  ]
};

export default GrantList;
