import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {
  APP_LABELS,
  DATE_SHOWALL_ARRAY,
  STORAGE_KEYS
} from '@config/app-config';
import BranchesIconLight from '@static/images/swimRegistration/swim-safety-branches-light.svg';
import BranchesIconTransparent from '@static/images/swimRegistration/swim-branch-transparent.svg';
import { getSwimAssociationDashboardCountList } from '@redux/siwm-safety/association-dashboard/actions';
import moment from 'moment';
import BlockUi from 'react-block-ui';
import { connect } from 'react-redux';
import { Loader } from 'react-loaders';
import SwimIconBlueLight from '@static/images/swimRegistration/swim-safety-blue-swim-light.svg';
import SwimIconBlueTransparent from '@static/images/swimRegistration/swim-safety-swim-transparent.svg';
import ProfileIconYellowLight from '@static/images/swimRegistration/swim-safety-profiles-yellow.svg';
import ProfileIconYellowTransparent from '@static/images/swimRegistration/swim-safety-profiles-yellow-transparent.svg';
import { AssociationAdminLoginDetailsSelector } from '@redux/login/selectors';
import ViewDetailsPopup from './view-details-popup';
import BranchTable from './BranchsTable/index';
import ProgramTable from './ProgramsTable/index';
import ProfileTable from './ProfileTables/index';
import SecureStorage from '../../../../utilities/SecureStorage';

const Dashboard = props => {
  const { handleGetDashBoardCount, location, LoginUserData } = props;
  const defaultStartDate = moment().subtract(90, 'd').format('MM-DD-YYYY');
  const defaultEndDate = moment().format('MM-DD-YYYY');
  const [activeTab, setActiveTab] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [loader, setLoader] = useState(false);
  const [showAll, setShowAll] = useState(true);
  const [countData, setCountData] = useState({});
  const [selectedRange, setSelectedRange] = useState('true');
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [isFilterClear, setIsFilterClear] = useState(false);
  const role = get(LoginUserData, 'user.role[0]');
  const associateData = JSON.parse(
    sessionStorage.getItem('yusa_selected_associate')
  );
  const associationNumber = get(associateData, 'associationNumber', '');
  const selectedTab = sessionStorage.getItem('swim_Program_selected_tab');
  const Offset = new Date().getTimezoneOffset();

  const { state } = location;

  const handleTabs = tab => {
    if (tab === 'Branches') {
      setActiveTab(tab);
      sessionStorage.setItem('swim_Program_selected_tab', tab);
    }
    if (tab === 'Swim Programs') {
      setActiveTab(tab);
      sessionStorage.setItem('swim_Program_selected_tab', tab);
    }
    if (tab === 'Profiles') {
      setActiveTab(tab);
      sessionStorage.setItem('swim_Program_selected_tab', tab);
    }
  };

  useEffect(() => {
    SecureStorage.setItem(
      STORAGE_KEYS.DASHBOARD_URL,
      role === APP_LABELS.YUSA_DATA_STEWARD
        ? '/swim/dashboard'
        : '/swim/association/dashboard'
    );
    setActiveTab(selectedTab || 'Branches');
  }, []);

  const filterDate = days => {
    if (days === 'Custom') {
      setStartDate('');
      setEndDate('');
      setShowAll(false);
    } else if (days === '1') {
      setStartDate(moment().subtract(days, 'd').format('MM-DD-YYYY'));
      setEndDate(moment().subtract(days, 'd').format('MM-DD-YYYY'));
      setShowAll(false);
    } else if (days === true) {
      setShowAll(days);
      setStartDate(moment().format('MM-DD-YYYY'));
      setEndDate(moment().format('MM-DD-YYYY'));
    } else {
      setStartDate(moment().subtract(days, 'd').format('MM-DD-YYYY'));
      setEndDate(defaultEndDate);
      setShowAll(false);
    }
  };

  const getDashboardCounts = async () => {
    setLoader(true);
    const showAllOption = selectedRange === 'true';
    setShowAll(showAllOption);
    await handleGetDashBoardCount(
      startDate,
      endDate,
      Offset,
      showAllOption,
      associationNumber
    )
      .then(result => {
        if (result?.data?.data) {
          setLoader(false);
          setCountData(result?.data?.data);
        } else {
          setLoader(false);
        }
      })
      .catch(() => {
        setLoader(false);
      });
  };

  useEffect(() => {
    getDashboardCounts();
  }, []);

  const handleApply = () => {
    setIsFilterActive(true);
    getDashboardCounts();
  };

  const clearFilters = () => {
    setStartDate(moment().format('MM-DD-YYYY'));
    setEndDate(moment().format('MM-DD-YYYY'));
    setSelectedRange(true);
    setShowAll(true);
    setIsFilterClear(true);
    setIsFilterActive(false);
  };

  const handleClear = async () => {
    clearFilters();
    await handleGetDashBoardCount(
      moment().format('MM-DD-YYYY'),
      moment().format('MM-DD-YYYY'),
      Offset,
      true,
      associationNumber
    )
      .then(result => {
        if (result?.data?.data) {
          setLoader(false);
          setCountData(result?.data?.data);
        } else {
          setLoader(false);
        }
      })
      .catch(() => {
        setLoader(false);
      });
  };

  return (
    <BlockUi
      blocking={loader}
      message="Loading, please wait"
      loader={<Loader active type="semi-circle-spin" />}
    >
      <div className="ymca-wrapper">
        <main className="page-container">
          <div className="ymca-data-wrapper volunteer-dashboard-wrapper">
            <div className="container">
              <section className="dashboard-header ">
                <div className="row align-items-end mt-3 justify-content-end ">
                  <div className="col-lg-9 col-md-9 col-sm-12">
                    <div className="ymca-associate-info-wrapper">
                      <h2 className="ymca-sub-heading mb-2">
                        {APP_LABELS.NAV_DASHBOARD}
                      </h2>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-12 text-lg-end text-md-end">
                    {/* <Link
                      className="btn ymca-primary-btn login-btn"
                      to="/swim/upload/profile"
                    >
                      + Upload Multiple Student Profiles
                    </Link> */}
                  </div>
                </div>
              </section>

              <div className="row my-4">
                <div className="col-lg-3 ymca-label d-flex align-items-center">
                  {' '}
                  <div>
                    <span className="txt-mandatory"> Range Selection</span>
                    <select
                      className="fieldSetSelect mt-1"
                      value={selectedRange}
                      onChange={e => {
                        setSelectedRange(e.target.value);
                        filterDate(e.target.value);
                        setIsFilterActive(false);
                        setIsFilterClear(false);
                      }}
                      defaultValue={true}
                    >
                      {DATE_SHOWALL_ARRAY.map(items => (
                        <option key={items.id} value={items.value}>
                          {items.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <span className="ps-4 fs-14 mt-3">Or</span>
                </div>

                <div className="col-lg-auto ymca-label pe-0 ">
                  <span className="txt-mandatory"> From Date</span>
                  <div>
                    <input
                      type="date"
                      onChange={e => {
                        setStartDate(e.target.value);
                        setEndDate('');
                        setIsFilterActive(false);
                        setIsFilterClear(false);
                      }}
                      value={startDate}
                      disabled={selectedRange !== 'Custom'}
                      className={`${
                        selectedRange === 'Custom'
                          ? ''
                          : 'cursor-not-allowed ymca-disabled-section'
                      } fieldSet mt-1`}
                      min={moment(new Date('1/1/1901 12:00:00')).format(
                        'YYYY-MM-DD'
                      )}
                      // onKeyDown={e => {
                      //   e.preventDefault();
                      // }}
                      max={moment(new Date())
                        .subtract(1, 'd')
                        .format('YYYY-MM-DD')}
                    />
                  </div>
                </div>
                <div className="col-lg-2 ymca-label">
                  <span className="txt-mandatory"> To Date</span>
                  <div>
                    <input
                      type="date"
                      onChange={e => {
                        setEndDate(e.target.value);
                        setIsFilterActive(false);
                        setIsFilterClear(false);
                      }}
                      // onKeyDown={e => {
                      //   e.preventDefault();
                      // }}
                      disabled={selectedRange !== 'Custom' || startDate === ''}
                      className={`${
                        selectedRange !== 'Custom' || startDate === ''
                          ? 'cursor-not-allowed ymca-disabled-section'
                          : ''
                      } fieldSet mt-1`}
                      value={endDate}
                      min={
                        selectedRange === 'Custom'
                          ? moment(new Date(startDate))
                              .add(1, 'days')
                              .format('YYYY-MM-DD')
                          : ''
                      }
                      max={
                        moment(new Date())
                          .add(1, 'Y')
                          .format('YYYY-MM-DD')
                      }
                    />
                  </div>
                </div>

                <div className="col-lg-1 mt-1 ps-0">
                  <button
                    type="button"
                    onClick={() => {
                      handleApply();
                    }}
                    disabled={
                      (startDate === '' || endDate === '') &&
                      selectedRange === 'Custom'
                    }
                    className="btn ymca-primary-btn apply-btn custom-align"
                  >
                    <span> Apply</span>
                  </button>
                </div>
                <div className="col-lg-1 mt-1">
                  <button
                    type="button"
                    onClick={() => handleClear()}
                    className="btn  ymc-outlineBtn apply-btn custom-align"
                  >
                    Clear
                  </button>
                </div>
              </div>

              <section className="stats-data">
                <div className="row ">
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <button
                      type="button"
                      className={`swim-uploadDataCards ${
                        activeTab === 'Branches'
                          ? 'swim-active-branches'
                          : 'swim-safety-associations'
                      } w-100`}
                      onClick={() => handleTabs('Branches')}
                    >
                      <div className="d-flex align-items-center h-100">
                        <div className="card-icon">
                          {activeTab === 'Branches' ? (
                            <img
                              src={BranchesIconTransparent}
                              className="dark-icon"
                              alt="icon"
                            />
                          ) : (
                            <img
                              src={BranchesIconLight}
                              className="dark-icon"
                              alt="icon"
                            />
                          )}
                        </div>
                        <div className="card-title">
                          <h4>{countData?.branchesCount || '0'}</h4>
                          <p> Number of Branches</p>
                        </div>
                      </div>
                    </button>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <button
                      type="button"
                      className={`swim-uploadDataCards
                       ${
                         activeTab === 'Swim Programs'
                           ? 'swim-safety-branches-active'
                           : 'swim-safety-branches'
                       } w-100`}
                      onClick={() => handleTabs('Swim Programs')}
                    >
                      <div className="d-flex align-items-center h-100">
                        <div className="card-icon">
                          {activeTab === 'Swim Programs' ? (
                            <img
                              src={SwimIconBlueTransparent}
                              className="dark-icon"
                              alt="icon"
                            />
                          ) : (
                            <img
                              src={SwimIconBlueLight}
                              className="dark-icon"
                              alt="icon"
                            />
                          )}
                        </div>
                        <div className="card-title">
                          <h4>{countData?.swimProgramsCount || '0'}</h4>
                          <p>Number of Swim Programs</p>
                        </div>
                      </div>
                    </button>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <button
                      type="button"
                      className={`swim-uploadDataCards ${
                        activeTab === 'Profiles'
                          ? 'swim-saftey-programs-active'
                          : 'swim-saftey-programs'
                      } w-100`}
                      onClick={() => handleTabs('Profiles')}
                    >
                      <div className="d-flex align-items-center h-100">
                        <div className="card-icon">
                          {activeTab === 'Profiles' ? (
                            <img
                              src={ProfileIconYellowTransparent}
                              className="dark-icon"
                              alt="icon"
                            />
                          ) : (
                            <img
                              src={ProfileIconYellowLight}
                              className="dark-icon"
                              alt="icon"
                            />
                          )}
                        </div>
                        <div className="card-title">
                          <h4>{countData?.profilesCount || '0'}</h4>
                          <p>Number of Profiles</p>
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
              </section>
              <hr className="custom-hr mt-0" />
              {activeTab === 'Branches' && (
                <BranchTable
                  setLoader={setLoader}
                  state={state}
                  recallCount={getDashboardCounts}
                  startDate={startDate}
                  endDate={endDate}
                  showAll={showAll}
                  isFilterActive={isFilterActive}
                  isFilterClear={isFilterClear}
                />
              )}
              {activeTab === 'Swim Programs' && (
                <ProgramTable
                  setLoader={setLoader}
                  recallCount={getDashboardCounts}
                  startDate={startDate}
                  endDate={endDate}
                  showAll={showAll}
                  isFilterActive={isFilterActive}
                  isFilterClear={isFilterClear}
                />
              )}
              {activeTab === 'Profiles' && (
                <ProfileTable
                  setLoader={setLoader}
                  recallCount={getDashboardCounts}
                  startDate={startDate}
                  endDate={endDate}
                  showAll={showAll}
                  isFilterActive={isFilterActive}
                  isFilterClear={isFilterClear}
                />
              )}
            </div>
          </div>
        </main>

        <ViewDetailsPopup
          showViewDetailsPopup={showPopup}
          handleCloseNewStaffPopup={() => setShowPopup(false)}
          popupTitle="Profile Details"
          popupData={{}}
        />
      </div>
    </BlockUi>
  );
};

Dashboard.propTypes = {
  handleGetDashBoardCount: PropTypes.func.isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  LoginUserData: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapStateToProps = state => ({
  LoginUserData: AssociationAdminLoginDetailsSelector(state)
});

const mapDispatchToProps = {
  handleGetDashBoardCount: getSwimAssociationDashboardCountList
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
