// eslint-disable-next-line import/prefer-default-export
import React from 'react';

import EditPencilIcon from '@static/images/swimRegistration/edit-pencil-icon.svg';
export const METADATA_SCORING_COLUMNS = ({ handleEdit }) => [
  {
    Header: 'Group ID',
    accessor: 'scoringGroupID',
    className: 'notranslate'
  },
  {
    Header: 'Group Name',
    accessor: 'scoringGroupName',
    className: 'notranslate'
  },
  {
    Header: 'Subgroup ID',
    accessor: 'evalGroupId',
    className: 'notranslate ymca-word-break ymca-ellipse'
  },
  {
    Header: 'Subgroup Name',
    accessor: 'evalGroupName',
    className: 'notranslate ymca-word-break ymca-ellipse'
  },
  {
    Header: 'Subgroup Level',
    accessor: 'evalGroup',
    className: 'notranslate ymca-word-break ymca-ellipse'
  },
  {
    Header: 'Group Type',
    accessor: 'evalGroupType',
    className: 'notranslate ymca-word-break ymca-ellipse'
  },
  {
    Header: 'Points Possible',
    accessor: 'pointsPossible',
    className: 'notranslate ymca-word-break ymca-ellipse'
  },
  {
    Header: 'Min Points Req',
    accessor: 'minPointsReq',
    className: 'notranslate ymca-word-break ymca-ellipse'
  },

  {
    Header: 'Earned Score',
    accessor: 'earnedScore',
    className: 'notranslate ymca-word-break ymca-ellipse'
  },
  {
    Header: 'Actions',
    accessor: 'isHidden',
    className: 'hide',
    Cell: v => (
      <>
        <div className="d-flex">
          <button
            className="btn table-link"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            type="button"
            onClick={() => handleEdit(v)}
          >
            <img
              src={EditPencilIcon}
              className="me-2 mb-1"
              alt="edit-pencil-icon"
            />
          </button>
        </div>
      </>
    )
  }
];
